import { SportCode } from '@incentivegames/ig-types/lib/enums/sport/sportCode';
import { AsyncResult, success, fail } from 'common/result';
import { getHeaders, predictorProxy } from './proxies';
import { APIError, handleAPIError } from './util';
import config from 'theme/config';
import { devTools } from 'common/devtools';

const apiVersion = process.env.REACT_APP_PREDICTOR_API_VERSION
  ? `/v${process.env.REACT_APP_PREDICTOR_API_VERSION}`
  : '';

const appendCountryRegion = config.controller.sendCountryRegionToPredictor;

/*============= PREDICTOR GAME ==============*/

export interface PredictorPrize {
  readonly prizeAmount: number;
  readonly prizeCode: string;
  readonly prizeCumulative: boolean;
  readonly prizeCurrencyCode: string;
  readonly prizeGuaranteed: boolean;
  readonly prizeMaxPosition: number;
  readonly prizeMinPosition: number;
  readonly prizeSplit: boolean;
  readonly prizeMultiplier: number;
  readonly prizeSettled: boolean;
}

export interface PredictorGame {
  readonly predictorId: number;
  readonly eventGroupId: number;
  readonly openTime: number;
  readonly closeTime: number;
  readonly displayEndTime: number;
  readonly minScore: number;
  readonly maxScore: number;
  readonly isEditEnabled: boolean;
  readonly prizes: ReadonlyArray<PredictorPrize>;
}

/*============= PREDICTOR ENTRY ==============*/

export interface PredictorEventParticipant {
  readonly participantName: string;
  readonly participantCode: string;
  readonly position: number;
  readonly participantId: number;
  readonly attributes?: Array<{ name: string; value: string }>;
}

export interface PredictorEntryPick {
  readonly eventId: number;
  readonly eventStartTime: number;
  readonly eventPeriod: string;
  readonly homeScore: number;
  readonly awayScore: number;
  readonly points: number;
  readonly eventParticipants: [PredictorEventParticipant, PredictorEventParticipant];
  readonly eventHomeScore: number | null;
  readonly eventAwayScore: number | null;
  readonly resulted?: boolean;
}

export interface PredictorEntry {
  readonly predictorId: string;
  readonly entryTime: number;
  readonly receipt: string;
  readonly currencyCode: string;
  readonly picks: ReadonlyArray<PredictorEntryPick>;
  readonly returns: number;
  readonly totalPoints: number;
  readonly settled: boolean;
  readonly prizes?: ReadonlyArray<PredictorPrize>;
}

/*============= PREDICTOR ENTRY UPDATE ==============*/

// Request format to create/update a predictor entry
export interface PredictorEntryUpdate {
  readonly predictorId: number;
  readonly uniqueId: string;
  readonly picks: Array<{
    readonly eventId: number;
    readonly homeScore: number;
    readonly awayScore: number;
  }>;
  readonly countryCode?: string;
  readonly countryRegion?: string;
}

// Partial pick via PUT /entry response
export interface PredictorUpdatedPick {
  readonly eventId: number;
  readonly homeScore: number;
  readonly awayScore: number;
  readonly eventParticipants: [PredictorEventParticipant, PredictorEventParticipant];
}

export interface GetPredictorStatsProps {
  predictorId: number;
  countryCode: string;
  countryRegion: string;
  currencyCode?: string;
}

export interface GetPredictorStatsResponse {
  predictorId: number;
  predictorType: string;
  predictorStartTime: number;
  predictorNumPicks: number;
  predictorEntries: number;
  predictorEventsComplete: number;
  predictorStats: PredictorStat[];
}

export interface PredictorStat {
  correctPicks: number;
  entryCount: number;
  entryPercentage: number;
  position: number;
  prizes: PredictorStatPrize[];
  prize?: PredictorStatPrize;
}

export interface PredictorStatPrize {
  prizeCode: string;
  prizeCurrencyCode: string;
  prizeAmount: number;
  prizeSplit: boolean;
  prizeGuaranteed: boolean;
}

/*============= PREDICTOR SERVICE ==============*/

export interface PredictorService {
  getCurrentGames: (countryCode: string, regionCode: string) => AsyncResult<PredictorGame[], APIError>;
  getPredictorEntry: (token: string, predictorId: number) => AsyncResult<PredictorEntry | undefined, APIError>;
  getHistoricEntries: (token: string) => AsyncResult<PredictorEntry[], APIError>;
  createPredictorEntry: (token: string, payload: PredictorEntryUpdate) => AsyncResult<PredictorEntry, APIError>;
  updatePredictorEntry: (
    token: string,
    receipt: string,
    payload: PredictorEntryUpdate,
  ) => AsyncResult<PredictorUpdatedPick[], APIError>;
  getPredictorStats: (props: GetPredictorStatsProps) => AsyncResult<GetPredictorStatsResponse, APIError>;
}

/*============= SERVICE IMPLEMENTATION ==============*/

export const predictorService: PredictorService = {
  getCurrentGames: async (countryCode, regionCode, sportCodes: SportCode[] = [SportCode.football]) => {
    try {
      // If we not are not playing default pick6, we append predictor subtype in request
      const predictorSubType = config.gameType !== 'predict6' ? `&predictorSubType=${config.gameType}` : '';
      let url = '/predictors?predictorType=pick6' + predictorSubType;

      if (appendCountryRegion && countryCode.length) {
        devTools.log('Appending countrycode', countryCode);
        url += `&countryCode=${countryCode}`;
      }

      if (appendCountryRegion && regionCode.length) {
        devTools.log('Appending regioncode', regionCode);
        url += `&countryRegion=${regionCode}`;
      }

      if (sportCodes && sportCodes.length) {
        devTools.log('Appending sportCodes', sportCodes);
        url += sportCodes.map((sc) => `&sportCode=${sc}`);
      }

      const response = await predictorProxy.get(url, {
        headers: getHeaders(),
      });
      return success(response.data.predictions);
    } catch (error) {
      return fail(handleAPIError(error));
    }
  },

  getPredictorEntry: async (userToken, predictorId) => {
    try {
      // If we not are not playing default pick6, we append predictor subtype in request
      const predictorSubType = config.gameType !== 'predict6' ? `&predictorSubType=${config.gameType}` : '';
      const path = `/entries?predictorId=${predictorId}&predictorType=pick6` + predictorSubType;
      const response = await predictorProxy.get(path, {
        headers: getHeaders(userToken),
      });
      return success(response.data.entries[0]);
    } catch (error) {
      return fail(handleAPIError(error));
    }
  },

  getHistoricEntries: async (userToken, sportCode: SportCode = SportCode.football) => {
    try {
      // If we not are not playing default pick6, we append predictor subtype in request
      const predictorSubType = config.gameType !== 'predict6' ? `&predictorSubType=${config.gameType}` : '';
      const settledEntries = config.controller.showUnsettledEntriesInHistory ? '' : '&status=settled';
      const path = `/entries?sportCode=${sportCode}&predictorType=pick6` + settledEntries + predictorSubType;
      const response = await predictorProxy.get(path, { headers: getHeaders(userToken) });
      return success(response.data.entries);
    } catch (error) {
      return fail(handleAPIError(error));
    }
  },

  createPredictorEntry: async (userToken, selections) => {
    try {
      const response = await predictorProxy.post(`/entry${apiVersion}`, selections, {
        headers: getHeaders(userToken),
      });
      return success(response.data);
    } catch (error) {
      return fail(handleAPIError(error));
    }
  },

  updatePredictorEntry: async (userToken, receipt, selections) => {
    try {
      const response = await predictorProxy.put(`/entry/${receipt}${apiVersion}`, selections, {
        headers: getHeaders(userToken),
      });
      return success(response.data.picks);
    } catch (error) {
      return fail(handleAPIError(error));
    }
  },

  getPredictorStats: async ({ predictorId, countryCode, countryRegion, currencyCode }: GetPredictorStatsProps) => {
    if (!predictorId) return fail(handleAPIError(new Error('Predictor Id invalid')));

    try {
      const response = await predictorProxy.get<GetPredictorStatsResponse>(`/predictorStats`, {
        params: {
          predictorId,
          countryCode,
          countryRegion,
          currencyCode,
        },
        headers: getHeaders(),
      });
      return success(response.data);
    } catch (error) {
      return fail(handleAPIError(error));
    }
  },
};
