import { useCallback, useEffect, useState } from 'react';
import './Countdown.scss';
import { useTranslation } from 'react-i18next';

interface CountdownProps {
  countdownTargetTime: number;
  className?: string;
}

const Countdown: React.FC<CountdownProps> = ({ countdownTargetTime, className }) => {
  const { t } = useTranslation();

  const getRemainingTime = useCallback(() => {
    const remainingTime = countdownTargetTime - Date.now();
    // If the time has elapsed, return 0 for all values
    if (remainingTime <= 0) return { days: 0, hours: 0, minutes: 0, seconds: 0 };

    const secsMultiplier = 1000;
    const minsMultiplier = secsMultiplier * 60;
    const hoursMultiplier = minsMultiplier * 60;
    const daysMultiplier = hoursMultiplier * 24;

    return {
      days: Math.floor(remainingTime / daysMultiplier),
      hours: Math.floor((remainingTime % daysMultiplier) / hoursMultiplier),
      minutes: Math.floor((remainingTime % hoursMultiplier) / minsMultiplier),
      seconds: Math.floor((remainingTime % minsMultiplier) / secsMultiplier),
    };
  }, [countdownTargetTime]);

  const [remainingTime, setRemainingTime] = useState(getRemainingTime());
  // Capture time component was rendered
  const [renderTime] = useState(Date.now());

  useEffect(() => {
    // Update State every second with new time
    const interval = setInterval(() => {
      setRemainingTime(getRemainingTime());
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [countdownTargetTime, getRemainingTime]);

  const formatTimeSegment = (time: number) => {
    return time < 10 ? `0${time}` : time;
  };

  // If the target time is in the past, don't render the component
  if (countdownTargetTime < renderTime) return null;

  return (
    <div className='l-countdown'>
      <div className='l-countdown__item'>
        <span>{formatTimeSegment(remainingTime.days)}</span>
        <span>{t('countdown-day')}</span>
      </div>
      <div className='l-countdown__item'>
        <span>{formatTimeSegment(remainingTime.hours)}</span>
        <span>{t('countdown-hour')}</span>
      </div>
      <div className='l-countdown__item'>
        <span>{formatTimeSegment(remainingTime.minutes)}</span>
        <span>{t('countdown-minute')}</span>
      </div>
      <div className='l-countdown__item'>
        <span>{formatTimeSegment(remainingTime.seconds)}</span>
        <span>{t('countdown-second')}</span>
      </div>
    </div>
  );
};

export default Countdown;
