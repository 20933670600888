import React, { lazy } from 'react';

const livescoreMap: Record<string, Record<string, string[]>> = require('./map.json');
const componentMap: Map<string, React.LazyExoticComponent<React.ComponentType<any>>> = new Map();

for (const [sport, league] of Object.entries(livescoreMap)) {
  for (const [leagueName, teams] of Object.entries(league)) {
    teams.forEach((team) =>
      componentMap.set(
        `${sport}/${team}`,
        lazy(async () => {
          const strip = (await import(`./png/${sport}/${leagueName}/${team}.png`)).default;
          return {
            default: ({ className }: { className?: string }) => <img className={className} src={strip} alt={team} />,
          };
        }),
      ),
    );
  }
}

const getComponent = (
  teamCode: string,
  sportCode: string,
): React.LazyExoticComponent<React.ComponentType<any>> | undefined => componentMap.get(`${sportCode}/${teamCode}`);

export default getComponent;
