import React from 'react';
import { useTranslation } from 'react-i18next';
import { communicationUtilities } from '@incentivegames/ig-frontend-common/lib/utilities/communicationUtilities';
import { RedirectType } from '@incentivegames/ig-types/lib/enums/communication/redirectType';

import './Footer.scss';

const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className='l-footer'>
      <div className='l-footer__links l-footer__links--underline'>
        <div
          className='l-footer__links__link'
          onClick={() => communicationUtilities.sendPostMessage.redirect(RedirectType.faqs)}
        >
          {t('footer-faqs')}
        </div>
        <div>|</div>
        <div
          className='l-footer__links__link'
          onClick={() => communicationUtilities.sendPostMessage.redirect(RedirectType.termsandconditions)}
        >
          {t('footer-t&cs')}
        </div>
      </div>
    </div>
  );
};

export default Footer;
