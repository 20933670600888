import { Storage } from '@incentivegames/ig-frontend-common/lib/contexts/storageContext';
import { devTools } from 'common/devtools';
import { PickData } from './GameState.types';

// 15 mins
const storageTime = 1000 * 60 * 15;

/**
 * Validate input data matches shape of StoragePick
 */
export const isStoragePick = (input: any): input is PickData => {
  if (typeof input === 'object') {
    if (input === null) return false;
    const eventId = typeof input.eventId === 'number';
    const homeTeamPrediction = typeof input.homeTeamPrediction === 'number';
    const awayTeamPrediction = typeof input.awayTeamPrediction === 'number';
    return eventId && homeTeamPrediction && awayTeamPrediction;
  }
  return false;
};

/**
 * Validate input data matches array of StoragePicks
 */
export const isStoragePicks = (input: any): input is PickData[] => {
  if (Array.isArray(input)) return input.every(isStoragePick);
  return false;
};

/**
 * Function to store user pick data in storage format for pick6 game
 */
export const localSavePicks = (storage: Storage, predictorId: number, pickData: PickData[], now: number) => {
  try {
    const expireTime = new Date(now + storageTime);
    storage.setValue(`predictor-${predictorId}`, JSON.stringify(pickData), expireTime);
    devTools.log('saved local predictions', pickData);
  } catch {
    devTools.log('error saving local predictions');
  }
};

/**
 * Function to retrieve and validate picks from user local storage
 */
export const getLocalPicks = (storage: Storage, predictorId: number) => {
  try {
    const localSavedString: string | undefined = storage.getValue(`predictor-${predictorId}`);

    if (localSavedString) {
      const parsed = JSON.parse(localSavedString);
      if (isStoragePicks(parsed)) {
        devTools.log('found locally saved preditions', parsed);
        return parsed;
      }
    }

    devTools.log('no locally saved predictions');
  } catch {
    devTools.log('error retrieving local predictions', predictorId);
  }
};

/**
 * Function to clear locally saved picks for a pick6 game
 */
export const clearLocalPicks = (storage: Storage, predictorId: number) => {
  try {
    storage.remove(`predictor-${predictorId}`);
    devTools.log('removed saved selections for predictor', predictorId);
  } catch {
    devTools.log('error removing local predictions', predictorId);
  }
};
