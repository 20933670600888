import React from 'react';
import { useTranslation } from 'react-i18next';

import './ConfirmChoicesModal.scss';

export interface ConfirmChoicesModalProps {
  open: boolean;
  onCancel: () => void;
  onSave: () => void;
}

const ConfirmChoicesModal: React.FC<ConfirmChoicesModalProps> = (props) => {
  const { t } = useTranslation();

  if (!props.open) return null;

  return (
    <>
      <div className="confirm-choices-modal-overlay" />
      <div className="confirm-choices-modal-position">
        <div id="confirm-choices-modal" className="confirm-choices-modal">
          <h1>{t('choice-screen-confirm-dialogue-edit-selections')}</h1>
          <p>{t('choice-screen-confirm-dialogue-you-have-already-made-selections-for-this-week')} {t('choice-screen-confirm-dialogue-do-you-want-to-overwrite')}</p>
          <div className='button_container'>
            <button className="btn btn__no" onClick={props.onCancel}>No</button>
            <button className="btn btn__yes" onClick={props.onSave}>Yes</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmChoicesModal;
