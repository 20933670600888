import { ReactNode, useLayoutEffect, useRef } from 'react';
import './UpsellCarousel.scss';

interface UpsellCarouselProps {
  children: ReactNode;
  numOfSlides: number;
}

export const UpsellCarousel = ({ children }: UpsellCarouselProps) => {
  const elementRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    // set scrollLeft to the middle of the carousel
    if (elementRef.current) {
      elementRef.current.scrollLeft = elementRef.current.scrollWidth / 2 - elementRef.current.clientWidth / 2;
    }
  }, []);

  return (
    <div className='upsell-carousel'>
      <div data-testid='UpsellCarousel' className='upsell-carousel__content' ref={elementRef}>
        {children}
      </div>
    </div>
  );
};
