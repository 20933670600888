import React from 'react';

import './ConfirmedPick.scss';

/*=========== ConfirmedPick ===========*/
export const ConfirmedPick: React.FC = ({ children }) => {
  return <div className="l-confirmed-pick">{children}</div>;
};

export interface IPredictedScore {
  homeTeamGoals: number;
  awayTeamGoals: number;
  className?: string;
}

/*=========== PredictedScore ===========*/
export const PredictedScore: React.FC<IPredictedScore> = ({ homeTeamGoals, awayTeamGoals, className }) => {
  return (
    <div className={`l-score-container ${className ? className : ''}`}>
      <div className="l-score-container-goals">{homeTeamGoals}</div>
      <div className="l-score-container-dash">-</div>
      <div className="l-score-container-goals">{awayTeamGoals}</div>
    </div>
  );
};
