import { communicationUtilities } from '@incentivegames/ig-frontend-common/lib/utilities/communicationUtilities';
import { useEffect } from 'react';

import { LoadingScreenProps } from 'screens/Screen.types';
import LoadingIcon from '../../components/LoadingIcon/LoadingIcon';
import './LoadingScreen.scss';

const LoadingScreen: React.FC<LoadingScreenProps> = ({ isInitialLoad }) => {
  // Attempt to scroll back to top of screen when loading, skipping the first load of the game
  useEffect(() => {
    if (isInitialLoad) return;
    const loading = document.getElementById('loading-screen');
    if (loading) loading.scrollIntoView(true);
    communicationUtilities.sendPostMessage.scrollTo(0);
  }, [isInitialLoad]);

  return (
    <div id="loading-screen" className="l-loading-screen">
      <LoadingIcon />
      <div id="cachePrimer">
        <p className="header-font-preloader header-font-preloader__title">&nbsp;</p>
        <p className="header-font-preloader header-font-preloader__prize">&nbsp;</p>
      </div>
    </div>
  );
};

export default LoadingScreen;
