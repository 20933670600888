import React, { useCallback, useContext, useState } from 'react';
import { PredictorGame } from 'services/predictorService';
import { SportsEvent } from 'services/sportsService';
import { GameState, PickData } from './GameState.types';
import { communicationUtilities } from '@incentivegames/ig-frontend-common/lib/utilities/communicationUtilities';
import { StorageContext } from '@incentivegames/ig-frontend-common/lib/contexts/storageContext';
import * as gs from './GameState.funcs';
import * as sf from './Storage.funcs';
import config from 'theme/config';
import Screen from 'screens';
import { isGamePooledPrize } from 'brands/bet365/components/PrizeBoard/PrizeBoard.func';

const controllerConfig = config.controller;

interface AppControllerNoTokenProps {
  currentGame: PredictorGame;
  events: SportsEvent[];
  userCurrency: string;
}

/**
 * The main state machine of the game when the user does not have session token (is logged out).
 */
export const AppControllerNoToken: React.FC<AppControllerNoTokenProps> = (props) => {
  const { currentGame, events, userCurrency } = props;

  /*========== LOCAL STORAGE RESTORE ============*/

  const { storage } = useContext(StorageContext);

  /*========== GAME STATE ===========*/

  const [gameState, setGameState] = useState<GameState>(() => {
    // If config enabled, check if any locally saved picks from logged out play
    let localSavedPicks: PickData[] | undefined = undefined;

    if (config.controller.savePicksOnNoToken) {
      localSavedPicks = sf.getLocalPicks(storage, currentGame.predictorId);
    }

    return gs.calcInitialState({
      game: currentGame,
      events,
      userCurrency,
      userLoggedIn: false,
      localSavedPicks,
      screenOnOpenGameNoEntry: controllerConfig.screenOnOpenGameNoEntry,
      screenOnOpenGameWithEntry: controllerConfig.screenOnOpenGameWithEntry,
    });
  });

  const isPooledPrizes = isGamePooledPrize(currentGame, userCurrency);

  /*========== PREDICTION STORAGE ============*/

  // Funtion to save predictions to local storage, will attempt to restored and submitted
  // to server on subsequent user play with a log-in token.
  const localSavePredictions = useCallback(
    (picks: PickData[]) => {
      if (config.controller.savePicksOnNoToken) {
        sf.localSavePicks(storage, currentGame.predictorId, picks, Date.now());
      }
    },
    [storage, currentGame],
  );

  /*=========== RENDER LOGIC ===========*/

  return (
    <div className='game-root'>{renderGameState(gameState, setGameState, localSavePredictions, isPooledPrizes)}</div>
  );
};

const renderGameState = (
  gameState: GameState,
  setGameState: (gameState: GameState) => void,
  localSavePredictions: (picks: PickData[]) => void,
  isPooledPrizes: boolean,
) => {
  if (gameState.tag === 'error') return <Screen.Error userLoggedIn={false} errorState={gameState} />;

  if (gameState.tag === 'loading') {
    return <Screen.Loading isInitialLoad={false} />;
  }

  if (gameState.tag === 'wait') {
    return (
      <Screen.Wait
        userLoggedIn={false}
        nextGameOpenTime={gameState.nextGameOpenTime}
        descendingPrizes={gameState.prizeData}
      />
    );
  }
  if (gameState.tag === 'start') {
    const onPlayClick = () => {
      communicationUtilities.sendPostMessage.login();
    };

    return (
      <Screen.Start
        countdownTargetTime={gameState.countdownTargetTime}
        topPrize={gameState.prizeData[0]}
        descendingPrizes={gameState.prizeData}
        onPlayClick={onPlayClick}
        openHistory={() => {}}
        userLoggedIn={false}
      />
    );
  }

  if (gameState.tag === 'choice') {
    const onEdit = (pickId: string, team: 'home' | 'away', value: number) => {
      const newPicks = gameState.editablePicks.map((pick) => {
        if (pick.pickId === pickId && team === 'home') return { ...pick, homeTeamPrediction: value };
        if (pick.pickId === pickId && team === 'away') return { ...pick, awayTeamPrediction: value };
        return pick;
      });
      setGameState({ ...gameState, editablePicks: newPicks });
    };

    const onSave = () => {
      localSavePredictions(gameState.editablePicks);
    };

    return (
      <Screen.Choice
        userLoggedIn={false}
        minPickValue={gameState.minPickValue}
        maxPickValue={gameState.maxPickValue}
        editablePicks={gameState.editablePicks}
        descendingPrizes={gameState.descendingPrizes}
        choiceType={gameState.choiceScreenType}
        gameCloseTime={gameState.gameCloseTime}
        onEdit={onEdit}
        onSave={onSave}
        activeTab={gameState.tag}
        changeScreen={gameState.changeScreen}
        previousTab='start'
        disablePayoutTable={true}
        isPooledPrizes={isPooledPrizes}
      />
    );
  }

  return null;
};

export default AppControllerNoToken;
