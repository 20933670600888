import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ConfirmedTick } from '../../assets/icons/PickWin.svg';

import './HeaderTick.scss';

interface HeaderTickProps {
  titleTranslationKey: string;
  subTextTranslationKey?: string;
}

const HeaderTick: React.FC<HeaderTickProps> = ({ titleTranslationKey, subTextTranslationKey }) => {
  const { t } = useTranslation();

  return (
    <div className="header-tick">
      <ConfirmedTick />
      <div className="header-tick__title"> {t(`${titleTranslationKey}`)}</div>
      <div className="header-tick__sub-text">{t(`${subTextTranslationKey}`)}</div>
    </div>
  );
};

export default HeaderTick;
