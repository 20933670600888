import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { UpsellScreenProps } from 'screens/Screen.types';
import { communicationUtilities } from '@incentivegames/ig-frontend-common/lib/utilities/communicationUtilities';
import { UpsellCarousel } from '../../components/UpsellCarousel/UpsellCarousel';
import { UpsellPick } from '../../components/UpsellPick/UpsellPick';
import { useDeviceDetect } from 'hooks/device';
import { isSome } from 'common/option';
import HeaderTick from '../../components/HeaderTick/HeaderTick';
import Countdown from '../../components/Countdown/Countdown';
import HistoryButton from '../../components/HistoryButton/HistoryButton';
import Logo from '../../components/Logo/Logo';
import config from 'theme/config';

import './UpsellScreen.scss';

const Picks: React.FC<{ picks: UpsellScreenProps['confirmedPicks'] }> = ({ picks }) => {
  return (
    <Fragment>
      {picks.map((pick) => (
        <UpsellPick
          key={pick.pickId}
          homeTeamCode={pick.homeTeamCode}
          awayTeamCode={pick.awayTeamCode}
          homeScore={pick.homeTeamPrediction}
          awayScore={pick.awayTeamPrediction}
          homeTeamFeedStripURL={pick.homeTeamFeedStripURL}
          awayTeamFeedStripURL={pick.awayTeamFeedStripURL}
        />
      ))}
    </Fragment>
  );
};

const UpsellScreen: React.FC<UpsellScreenProps> = (props) => {
  const { t } = useTranslation();
  const isTouchDevice = useDeviceDetect();

  const renderEditButton = () => {
    if (!props.showEditButton) return null;

    return (
      <button onClick={props.onEdit} className='upsell-screen__edit-button'>
        {t('upsell-screen-edit-button')}
      </button>
    );
  };

  const onOpenHistory = () => {
    communicationUtilities.sendPostMessage.resize(600);
    props.openHistory();
    return;
  };

  return (
    <div className='upsell-screen'>
      <HistoryButton openHistory={onOpenHistory} />
      <Logo className='upsell-screeen__logo' theme='dark-numbers'>
        <HeaderTick
          titleTranslationKey='upsell-screen-header-tick-title'
          subTextTranslationKey='upsell-screen-header-tick-sub-text'
        />
        <div className='upsell-screen__picks-container'>
          {config.settings.enableUpsellCarousel && isTouchDevice ? (
            <UpsellCarousel numOfSlides={props.confirmedPicks.length}>
              <Picks picks={props.confirmedPicks} />
            </UpsellCarousel>
          ) : (
            <div className='upsell-screen__picks-grid'>
              <Picks picks={props.confirmedPicks} />
            </div>
          )}
        </div>
        {renderEditButton()}
        <Countdown countdownTargetTime={isSome(props.nextMatchTime) ? props.nextMatchTime.value : 0} />
      </Logo>
    </div>
  );
};

export default UpsellScreen;
