export interface Some<T> {
  readonly tag: 'some';
  readonly value: T;
}

export interface None {
  readonly tag: 'none';
}

export type Option<T> = Some<T> | None;

export const some = <T>(value: T): Option<T> => ({ tag: 'some', value });

export const none: Option<never> = { tag: 'none' };

export const isNone = (option: Option<unknown>): option is None => option.tag === 'none';

export const isSome = <T>(option: Option<T>): option is Some<T> => option.tag === 'some';
