// Brand Custom Screens Imports
import { CustomScreens } from 'theme/screens';

// Screen Types
import { AllScreens } from './Screen.types';

/**
 *  Used Screens when the running game. Here custom brand specific screen are injected and
 *  given a consistent name.
 * */
const Screen: AllScreens = {
  Loading: CustomScreens.Loading,
  HistoryModal: CustomScreens.HistoryModal,
  Start: CustomScreens.Start,
  Choice: CustomScreens.Choice,
  Confirmed: CustomScreens.Confirmed,
  Upsell: CustomScreens.Upsell,
  Results: CustomScreens.Results,
  Wait: CustomScreens.Wait,
  Error: CustomScreens.Error,
  PayoutTable: CustomScreens.PayoutTable,
};

export default Screen;
