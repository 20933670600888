import { isFail } from 'common/result';
import { PredictorEntry, PredictorEntryUpdate, PredictorGame, predictorService } from 'services/predictorService';
import { sportService, SportsEvent } from 'services/sportsService';
import {
  ChoiceState,
  ErrorState,
  GameResultState,
  GameState,
  HistoricGameData,
  HistoryModalState,
  PickData,
  PredictorUpdateData,
  ResultsState,
} from './GameState.types';
import { ConfigScreen } from 'interfaces/configurationInterfaces';
import { none, some, isNone, isSome } from 'common/option';
import { createUniqueId, postUpsellEntry } from './Util.funcs';
import { APIError } from 'services/util';
import * as gameFunc from './Game.func';
import * as entryFunc from './Entry.func';
import * as eventFunc from './Event.func';
import config from 'theme/config';

/*============ GAME TYPE CONFIG ==============*/

/**
 * The total length of the leaderboard is adjusted by the
 * game type set in the config file.
 */
const LEADERBOARD_LENGTH = config.gameType === 'predict4' ? 4 : 6;

/*============ CALC OF INITIAL GAME STATE ============*/

export interface InitialStateArgs {
  game: PredictorGame;
  events: SportsEvent[];
  entry?: PredictorEntry;
  localSavedPicks?: PickData[];
  userCurrency: string;
  userLoggedIn: boolean;
  screenOnOpenGameNoEntry: ConfigScreen;
  screenOnOpenGameWithEntry: ConfigScreen;
}

/**
 * Calculate the initial state after the first retrieved set of game/event/entry data.
 * The following states can be entered:
 * 1) Start State - game is open and the user can enter (config dependent)
 * 2) Choice State- game is open and user can enter (config dependent)
 * 3) Confirmed State - game is open with existing user entry (config dependent)
 * 4) Result State - game is open with user entry, but editing is disabled via admin
 * 5) Result State - game has closed and user has an entry
 * 6) Wait State - game has closed and user does not have an existing entry to view results
 * 7) Submit Locally Saved - user logged-in with locally saved picks from logged out play with no existing entry for current game
 */
export const calcInitialState = (args: InitialStateArgs): GameState => {
  const {
    game,
    events,
    entry,
    localSavedPicks,
    screenOnOpenGameNoEntry,
    screenOnOpenGameWithEntry,
    userCurrency,
  } = args;

  // Establish status of the game
  const now = Date.now();
  const gameIsOpen = now >= game.openTime && now < game.closeTime;
  const gameIsClosed = now >= game.closeTime;

  // Calc prize data for the various states
  // If the user already has an entry, we use the entry currency over URL/PostMessage
  const prizeCurrency = entry ? entry.currencyCode : args.userCurrency;
  const prizeData = gameFunc.calcPrizeData(prizeCurrency, game.prizes, LEADERBOARD_LENGTH);

  // If user logged-in, has locally saved picks from logged out play and no current entry,
  // enter Submit Saved Picks state, which will trigger API request for making an entry.
  if (args.userLoggedIn && localSavedPicks && gameIsOpen && !entry)
    return {
      tag: 'submit-locally-saved',
      uniqueId: createUniqueId(game.predictorId),
      picks: localSavedPicks,
    };

  // Game open with user entry and no editing allowed, go to results screen
  if (gameIsOpen && entry && !game.isEditEnabled)
    return {
      tag: 'results',
      gameResult: entryFunc.calcGameResultFromEntry(entry, prizeData),
      pickResults: entryFunc.calcPickResults(entry, events),
      prizeData: prizeData,
      firstEventStartTime: eventFunc.findEarliestEventStart(events),
    };

  // Game open
  if (gameIsOpen) {
    // Screen/State based on if user has entry or not and brand config setting
    const targetScreen = entry ? screenOnOpenGameWithEntry : screenOnOpenGameNoEntry;

    if (entry) {
      if (targetScreen === 'confirmed') {
        return {
          tag: 'confirmed',
          confirmedPicks: entryFunc.calcPickDataFromEntryPicks(entry.picks),
          firstEventStartTime: eventFunc.findEarliestEventStart(events),
          isEditEnabled: game.isEditEnabled,
          userClickedEdit: false,
          descendingPrizes: prizeData,
        };
      }

      if (targetScreen === 'upsell') {
        if (config.upsell.sendUpsellPostMessage) {
          postUpsellEntry(events, entry.picks, entry.receipt);
        }
        return {
          tag: 'upsell',
          userCurrency: userCurrency,
          confirmedPicks: entryFunc.calcPickDataFromEntryPicks(entry.picks),
          events: events,
          firstEventStartTime: eventFunc.findEarliestEventStart(events),
          isEditEnabled: game.isEditEnabled,
          userClickedEdit: false,
          descendingPrizes: prizeData,
        };
      }
    }

    if (targetScreen === 'start')
      return {
        tag: 'start',
        countdownTargetTime: game.closeTime,
        prizeData: prizeData,
        userClickedPlay: false,
      };

    if (targetScreen === 'choice') {
      return enterChoiceState(game, events, args.userCurrency, entry, localSavedPicks);
    }
  }

  // Game closed with user entry, go to results screen
  if (gameIsClosed && entry)
    return {
      tag: 'results',
      gameResult: entryFunc.calcGameResultFromEntry(entry, prizeData),
      pickResults: entryFunc.calcPickResults(entry, events),
      prizeData: prizeData,
      firstEventStartTime: eventFunc.findEarliestEventStart(events),
    };

  // Game closed with no user entry, go to wait screen
  return {
    tag: 'wait',
    nextGameOpenTime: game.displayEndTime,
    prizeData,
  };
};

/*============ ENTER CHOICE STATE FROM START STATE ============*/

/**
 * Enter the choice state from the start screen via two methods:
 * 1) If user has existing entry, use its data to generate the state
 * 2) If no existing entry, use game events to generate the state
 */
export const enterChoiceState = (
  game: PredictorGame,
  events: SportsEvent[],
  userCurrency: string,
  entry?: PredictorEntry,
  localSavedPicks?: PickData[],
): ChoiceState => {
  // Each time we enter the choice state, we roll a unique hash for the subsequent server create/update request
  const uniqueId = createUniqueId(game.predictorId);

  // Calc Prize data. If the user already has an entry, we use the entry currency over URL/PostMessage
  const prizeCurrency = entry ? entry.currencyCode : userCurrency;
  const prizeData = gameFunc.calcPrizeData(prizeCurrency, game.prizes, LEADERBOARD_LENGTH);

  // If entry exists, we can use its data to enter the choice screen
  // We store the entry receipt/currency in order to do a update request later
  if (entry)
    return {
      tag: 'choice',
      uniqueId,
      entryData: some({ receipt: entry.receipt, currency: entry.currencyCode }),
      minPickValue: game.minScore,
      maxPickValue: game.maxScore,
      editablePicks: entryFunc.calcPickDataFromEntryPicks(entry.picks),
      descendingPrizes: prizeData,
      userClickedConfirm: false,
      choiceScreenType: 'edit',
      gameCloseTime: game.closeTime,
      changeScreen: () => {},
    };

  // Calculate required pick data from event data
  // If no entry, we attempt to restore locally saved picks if present
  return {
    tag: 'choice',
    uniqueId,
    entryData: none,
    minPickValue: game.minScore,
    maxPickValue: game.maxScore,
    editablePicks: eventFunc.calcPickDataFromSportEvents(events, localSavedPicks),
    descendingPrizes: prizeData,
    userClickedConfirm: false,
    choiceScreenType: 'initial',
    gameCloseTime: game.closeTime,
    changeScreen: () => {},
  };
};

/*============ ENTER CHOICE STATE FROM CONFIRMED STATE ============*/

/**
 * The user has decided to edit their picks, we request the latest entry
 * and enter the choice state.
 */
export const enterChoiceStateViaEdit = async (game: PredictorGame, token: string): Promise<GameState> => {
  // Each time we enter the choice state, we roll a unique hash for the subsequent server create/update request
  const uniqueId = createUniqueId(game.predictorId);

  // Retrive user entry for game
  const entryResult = await predictorService.getPredictorEntry(token, game.predictorId);

  // Handle API error
  if (isFail(entryResult)) return calcErrorState(entryResult.value);

  const entry = entryResult.value;

  // We cannot edit picks with no existing entry
  if (!entry) {
    return { tag: 'error', errorType: 'generic' };
  }

  return {
    tag: 'choice',
    uniqueId,
    entryData: some({ receipt: entry.receipt, currency: entry.currencyCode }),
    minPickValue: game.minScore,
    maxPickValue: game.maxScore,
    editablePicks: entryFunc.calcPickDataFromEntryPicks(entry.picks),
    descendingPrizes: gameFunc.calcPrizeData(entry.currencyCode, game.prizes, LEADERBOARD_LENGTH),
    userClickedConfirm: false,
    choiceScreenType: 'edit',
    gameCloseTime: game.closeTime,
    changeScreen: () => {},
  };
};

/*============ SUBMIT USER PICKS ============*/

/**
 * Submit user picks choices to predictor API.
 * Depending on if there is an existing entry, it will do create/update request.
 * On create/update success enter the picks confirmed game state.
 */
export const submitPicks = async (
  game: PredictorGame,
  events: SportsEvent[],
  showUpsell: boolean,
  updateData: PredictorUpdateData,
): Promise<GameState> => {
  // Convert pick data into API request format
  const payload: PredictorEntryUpdate = {
    predictorId: updateData.predictorId,
    uniqueId: updateData.uniqueId,
    picks: updateData.userPicks.map((pick) => ({
      eventId: pick.eventId,
      homeScore: pick.homeTeamPrediction,
      awayScore: pick.awayTeamPrediction,
    })),
    countryCode:
      config.controller.sendCountryRegionToPredictor && updateData.countryCode ? updateData.countryCode : undefined,
    countryRegion:
      config.controller.sendCountryRegionToPredictor && updateData.countryRegion ? updateData.countryRegion : undefined,
  };

  // Calc common attributes for confirmed/upsell satte
  const firstEventStartTime = eventFunc.findEarliestEventStart(events);
  const isEditEnabled = game.isEditEnabled;
  const userClickedEdit = false;

  /*====== NO EXISTING ENTRY: POST REQUEST ======*/

  if (isNone(updateData.entryData)) {
    const createResult = await predictorService.createPredictorEntry(updateData.token, payload);

    // Handle API Error
    if (isFail(createResult)) return calcErrorState(createResult.value);

    if (config.upsell.sendUpsellPostMessage) {
      postUpsellEntry(events, createResult.value.picks, createResult.value.receipt);
    }

    const confirmedPicks = entryFunc.calcPickDataFromEntryPicks(createResult.value.picks);

    // If create success and upsell on, show upsell
    if (showUpsell)
      return {
        tag: 'upsell',
        userCurrency: createResult.value.currencyCode,
        confirmedPicks: confirmedPicks,
        events: events,
        firstEventStartTime,
        isEditEnabled,
        userClickedEdit,
      };

    // Else show default confirmed screen
    return {
      tag: 'confirmed',
      confirmedPicks: confirmedPicks,
      firstEventStartTime,
      isEditEnabled,
      userClickedEdit,
    };
  }

  /*====== EXISTING ENTRY - PUT REQUEST ======*/

  const entryReceipt = updateData.entryData.value.receipt;
  const entryCurrency = updateData.entryData.value.currency;

  const updateResult = await predictorService.updatePredictorEntry(updateData.token, entryReceipt, payload);

  // Handle API Error
  if (isFail(updateResult)) return calcErrorState(updateResult.value);

  if (config.upsell.sendUpsellPostMessage) {
    postUpsellEntry(events, updateResult.value, entryReceipt);
  }

  const confirmedPicks = entryFunc.calcPickDataFromEntryUpdate(updateResult.value, events);

  // If create success and upsell on, show upsell
  if (showUpsell)
    return {
      tag: 'upsell',
      userCurrency: entryCurrency,
      confirmedPicks: confirmedPicks,
      events: events,
      firstEventStartTime,
      isEditEnabled,
      userClickedEdit,
    };

  // Else show default confirmed screen
  return {
    tag: 'confirmed',
    confirmedPicks: confirmedPicks,
    firstEventStartTime,
    isEditEnabled,
    userClickedEdit,
  };
};

/*=========== POLL RESULTS DATA ============*/

/**
 * Function to check if requesting new events/entry is needed.
 * If game is finished no need to poll more data.
 */
export const shouldPollResults = (resultsState: ResultsState): boolean => {
  // Note, we could optimize this further and check more data in this state
  // to only poll when games are likely to be inplay.
  if (resultsState.gameResult.tag === 'in-progress') return true;
  else return false;
};

export interface PollResultsArgs {
  userToken: string;
  game: PredictorGame;
  userCurrency: string;
  screenOnOpenGameNoEntry: ConfigScreen;
  screenOnOpenGameWithEntry: ConfigScreen;
}

export const pollResults = async (args: PollResultsArgs): Promise<GameState> => {
  const { game, userToken, userCurrency, screenOnOpenGameNoEntry, screenOnOpenGameWithEntry } = args;

  const eventsRequest = await sportService.getGameEvents(game.eventGroupId);

  if (isFail(eventsRequest)) return fail(calcErrorState(eventsRequest.value));

  const events = eventsRequest.value;

  const entryRequest = await predictorService.getPredictorEntry(userToken, game.predictorId);

  // Handle API Error
  if (isFail(entryRequest)) return fail(calcErrorState(entryRequest.value));

  const entry = entryRequest.value;

  // With fresh event/entry data, recall the main function that calculates the state of the game
  return calcInitialState({
    game,
    events,
    entry,
    userCurrency,
    userLoggedIn: true,
    screenOnOpenGameNoEntry,
    screenOnOpenGameWithEntry,
  });
};

/*============ REQUEST HISTORY MODAL DATA ============*/

/**
 * Request required data to populate history modal and update history modal state
 */
export const loadEntryHistory = async (userToken: string, state: HistoryModalState): Promise<HistoryModalState> => {
  const entriesResult = await predictorService.getHistoricEntries(userToken);

  // Handle API Errors
  if (isFail(entriesResult)) return { ...state, status: 'error' };

  return {
    ...state,
    status: 'loaded',
    games: entriesResult.value.map(entryFunc.calcHistoricGameFromEntry),
  };
};

/*============ HISTORY MODAL TO RESULTS SCREEN ============*/

/**
 * View a fully settled game in results view. Will need to be modified
 * if we want to view in-progress games via history.
 */
export const enterResultsViaHistory = (game: HistoricGameData): ResultsState => {
  const gameResult: GameResultState = isSome(game.prizeWon)
    ? { tag: 'won', prize: game.prizeWon.value }
    : { tag: 'lost' };

  // We can't reliably retrieve an settled game for the prize display,
  // so no prizes for viewing results via the history modal
  return {
    tag: 'results',
    gameResult,
    pickResults: game.pickResults,
  };
};

/*============ ERRORS ============*/

/**
 * Map an API error to a game state error
 */
export const calcErrorState = (error: APIError): ErrorState => {
  switch (error.errorCode) {
    case 'invalid-token':
      return {
        tag: 'error',
        errorType: 'user-not-logged-in',
      };
    case 'player-not-found':
      return {
        tag: 'error',
        errorType: 'player-not-found',
      };
    case 'access-token-expired':
      return {
        tag: 'error',
        errorType: 'access-token-expired',
      };
    default:
      return {
        tag: 'error',
        errorType: 'generic',
      };
  }
};
