import React, { useCallback, useContext, useEffect, useState } from 'react';

import Screen from 'screens';
import { isSome, none } from 'common/option';
import { StorageContext } from '@incentivegames/ig-frontend-common/lib/contexts/storageContext';
import { communicationUtilities } from '@incentivegames/ig-frontend-common/lib/utilities/communicationUtilities';
import {
  ChoiceState,
  GameState,
  HistoricGameData,
  HistoryModalState,
  PickData,
  SubmitLocallySavedPicks,
} from './GameState.types';
import { GetPredictorStatsResponse, PredictorEntry, PredictorGame } from 'services/predictorService';
import { SportsEvent } from 'services/sportsService';
import { devTools } from 'common/devtools';
import config from 'theme/config';
import * as gs from './GameState.funcs';
import * as sf from './Storage.funcs';
import './AppController.scss';
import { isGamePooledPrize } from 'brands/bet365/components/PrizeBoard/PrizeBoard.func';
import { delay } from './Util.funcs';

const controllerConfig = config.controller;

/*========== APP CONTROLLER ============*/

interface AppControllerProps {
  currentGame: PredictorGame;
  events: SportsEvent[];
  initialEntry?: PredictorEntry;
  userToken: string;
  userCurrency: string;
  userCountry?: string;
  userRegion?: string;
  history: HistoryModalState;
  openHistory: () => void;
  closeHistory: () => void;
  predictorStats: GetPredictorStatsResponse;
}

/**
 * The main state machine of the game when the user has a session token provided.
 */

export const AppController: React.FC<AppControllerProps> = (props) => {
  const {
    currentGame,
    events,
    initialEntry,
    userToken,
    userCurrency,
    userCountry,
    userRegion,
    history,
    openHistory,
    closeHistory,
    predictorStats,
  } = props;

  /*========== LOCAL STORAGE RESTORE ============*/

  const { storage } = useContext(StorageContext);

  /*========== GAME STATE ===========*/
  const [gameState, setGameState] = useState<GameState>(() => {
    // If config enabled, check if any locally saved picks from logged out play
    let localSavedPicks: PickData[] | undefined = undefined;

    if (config.controller.savePicksOnNoToken) {
      localSavedPicks = sf.getLocalPicks(storage, currentGame.predictorId);
    }

    return gs.calcInitialState({
      game: currentGame,
      events,
      entry: initialEntry,
      userLoggedIn: true,
      userCurrency,
      localSavedPicks,
      screenOnOpenGameNoEntry: controllerConfig.screenOnOpenGameNoEntry,
      screenOnOpenGameWithEntry: controllerConfig.screenOnOpenGameWithEntry,
    });
  });
  const [previousTab, setPreviousTab] = useState<string>('choice');
  const isPooledPrizes = isGamePooledPrize(currentGame, userCurrency);
  const [pollingInterval, setPollingInterval] = useState<NodeJS.Timeout>();

  /*========== START STATE -> CHOICE STATE ===========*/

  useEffect(() => {
    const enterChoiceScreen = async () => {
      const nextState = gs.enterChoiceState(currentGame, events, userCurrency, initialEntry);
      setGameState(nextState);
    };

    if (gameState.tag === 'start' && gameState.userClickedPlay) {
      enterChoiceScreen();
    }
  }, [currentGame, events, initialEntry, userToken, userCurrency, gameState]);

  /*========== CHOICE STATE -> CONFIRMED/UPSELL STATE  ===========*/

  useEffect(() => {
    const submitPicks = async (state: ChoiceState) => {
      const nextState = await gs.submitPicks(currentGame, events, config.upsell.showUpsell, {
        token: userToken,
        predictorId: currentGame.predictorId,
        uniqueId: state.uniqueId,
        entryData: state.entryData,
        userPicks: state.editablePicks,
        countryCode: userCountry,
        countryRegion: userRegion,
      });
      setGameState({ ...state, ...nextState });
    };

    if (gameState.tag === 'choice' && gameState.userClickedConfirm) {
      submitPicks(gameState);
      setGameState({ ...gameState, tag: 'loading' });
    }
  }, [currentGame, events, userToken, userCountry, userRegion, gameState]);

  /*========== CONFIRMED STATE -> CHOICE SCREEN ===========*/

  useEffect(() => {
    const enterChoiceScreen = async () => {
      const nextState = await gs.enterChoiceStateViaEdit(currentGame, userToken);
      setGameState(nextState);
    };

    if (gameState.tag === 'confirmed' && gameState.userClickedEdit) {
      enterChoiceScreen();
      setGameState({ ...gameState, tag: 'loading' });
    }
  }, [currentGame, userToken, gameState]);

  /*========== UPSELL STATE -> CHOICE SCREEN ===========*/

  useEffect(() => {
    const enterChoiceScreen = async () => {
      const nextState = await gs.enterChoiceStateViaEdit(currentGame, userToken);
      setGameState(nextState);
    };

    if (gameState.tag === 'upsell' && gameState.userClickedEdit) {
      enterChoiceScreen();
      setGameState({ ...gameState, tag: 'loading' });
    }
  }, [currentGame, userToken, gameState]);

  /*========== HISTORY MODAL -> RESULTS SCREEN ===========*/

  const viewHistoricGame = useCallback(
    (game: HistoricGameData) => {
      setGameState(gs.enterResultsViaHistory(game));
      closeHistory();
    },
    [closeHistory],
  );

  /*==== SUBMIT LOCALLY SAVED PICKS -> CONFIRMED/UPSELL SCREEN ====*/

  useEffect(() => {
    const submitSavedPicks = async (state: SubmitLocallySavedPicks) => {
      devTools.log('Submitting Locally Saved Picks...');
      const nextState = await gs.submitPicks(currentGame, events, config.upsell.showUpsell, {
        token: userToken,
        predictorId: currentGame.predictorId,
        uniqueId: state.uniqueId,
        entryData: none,
        userPicks: state.picks,
        countryCode: userCountry,
        countryRegion: userRegion,
      });
      // On success, remove saved picks from local storage
      if (nextState.tag !== 'error') storage.remove(`predictor-${currentGame.predictorId}`);
      setGameState({ ...state, ...nextState });
    };

    if (gameState.tag === 'submit-locally-saved') {
      submitSavedPicks(gameState);
    }
  }, [storage, currentGame, events, userToken, userCountry, userRegion, gameState]);

  /*==== RESULT STATE -> POLL INPLAY DATA ====*/

  useEffect(() => {
    if (!config.results.pollData || gameState.tag !== 'results') return;
    if (!gs.shouldPollResults(gameState)) {
      if (pollingInterval) clearInterval(pollingInterval);
      return;
    }
    if (pollingInterval) return;

    const pollData = async () => {
      devTools.log('Polling inplay data...');
      const nextState = await gs.pollResults({
        game: currentGame,
        userToken,
        userCurrency,
        screenOnOpenGameNoEntry: controllerConfig.screenOnOpenGameNoEntry,
        screenOnOpenGameWithEntry: controllerConfig.screenOnOpenGameWithEntry,
      });
      devTools.log('poll update', nextState);
      setGameState(nextState);
    };

    const staggerTime = config.results.pollTime ? Math.floor(Math.random() * config.results.pollTime) : 0;
    const msSinceGameStart =
      gameState.firstEventStartTime && isSome(gameState.firstEventStartTime)
        ? Date.now() - gameState?.firstEventStartTime?.value
        : undefined;
    // If we are in the first 5 seconds of the game, stagger the polling time (we will only do this once)
    if (staggerTime > 0 && msSinceGameStart && msSinceGameStart > 0 && msSinceGameStart < 5000) {
      devTools.log('set stagger time as: ', staggerTime);
      delay(staggerTime).then(() => {
        setPollingInterval(setInterval(pollData, config.results.pollTime));
      });
    } else {
      devTools.log('set timeout without stagger as: ', config.results.pollTime);
      setPollingInterval(setInterval(pollData, config.results.pollTime));
    }
  }, [gameState, currentGame, userToken, userCurrency, pollingInterval]);

  /*=========== TRIGGER UPSELL POST MESSAGE UPON CLICK ON EDIT ===========*/

  useEffect(() => {
    const userClickedEditExist = gameState.tag === 'upsell' || gameState.tag === 'confirmed';
    if (userClickedEditExist && config.upsell.sendUpsellPostMessage && gameState.userClickedEdit) {
      devTools.log('Sending tracking postMessage with entryUpdate');
      communicationUtilities.sendPostMessage.tracking('entryUpdate');
    }
  }, [gameState]);

  /*=========== PREMATCH CHECK FOR TABS PAYOUT TABLE ACTIVE  ===========*/
  const eventsStarted: boolean = !events.every((event) => event.eventPeriod === 'PM');

  /*=========== RENDER LOGIC ===========*/

  return (
    <div className='game-root'>
      <Screen.HistoryModal state={history} closeHistory={closeHistory} viewHistoricGame={viewHistoricGame} />
      {renderGameState(
        gameState,
        setGameState,
        setPreviousTab,
        openHistory,
        predictorStats,
        previousTab,
        eventsStarted,
        isPooledPrizes,
      )}
    </div>
  );
};

const renderGameState = (
  gameState: GameState,
  setGameState: (gameState: GameState) => void,
  setPreviousTab: (tag: string) => void,
  openHistory: () => void,
  predictorStats: GetPredictorStatsResponse,
  previousTab: string,
  eventsStarted: boolean,
  isPooledPrizes: boolean,
) => {
  const changeScreen = (tag: any) => {
    // prevent user from going to payout table if events haven't started
    if (tag === 'payout-table' && !eventsStarted) return;
    if (gameState.tag !== 'payout-table') setPreviousTab(gameState.tag);
    setGameState({ ...gameState, tag });
  };

  if (gameState.tag === 'payout-table') {
    return (
      <Screen.PayoutTable
        predictorStats={predictorStats}
        changeScreen={changeScreen}
        openHistory={openHistory}
        activeTab={gameState.tag}
        pickResults={gameState.pickResults}
        previousTab={previousTab}
        disablePayoutTable={!eventsStarted}
        isPooledPrizes={isPooledPrizes}
      />
    );
  }

  if (gameState.tag === 'error')
    return <Screen.Error userLoggedIn={true} errorState={gameState} openHistory={openHistory} />;

  if (gameState.tag === 'loading') {
    return <Screen.Loading isInitialLoad={false} />;
  }

  if (gameState.tag === 'start') {
    const onPlayClick = () => setGameState({ ...gameState, userClickedPlay: true });

    return (
      <Screen.Start
        countdownTargetTime={gameState.countdownTargetTime}
        topPrize={gameState.prizeData[0]}
        descendingPrizes={gameState.prizeData}
        onPlayClick={onPlayClick}
        openHistory={openHistory}
        userLoggedIn={true}
      />
    );
  }

  if (gameState.tag === 'wait')
    return (
      <Screen.Wait
        userLoggedIn={true}
        nextGameOpenTime={gameState.nextGameOpenTime}
        descendingPrizes={gameState.prizeData}
        openHistory={openHistory}
      />
    );

  if (gameState.tag === 'choice') {
    const onEdit = (pickId: string, team: 'home' | 'away', value: number) => {
      const newPicks = gameState.editablePicks.map((pick) => {
        if (pick.pickId === pickId && team === 'home') return { ...pick, homeTeamPrediction: value };
        if (pick.pickId === pickId && team === 'away') return { ...pick, awayTeamPrediction: value };
        return pick;
      });
      setGameState({ ...gameState, editablePicks: newPicks });
    };

    const onSave = () => {
      setGameState({ ...gameState, userClickedConfirm: true });
    };

    return (
      <Screen.Choice
        userLoggedIn={true}
        minPickValue={gameState.minPickValue}
        maxPickValue={gameState.maxPickValue}
        editablePicks={gameState.editablePicks}
        descendingPrizes={gameState.descendingPrizes}
        choiceType={gameState.choiceScreenType}
        gameCloseTime={gameState.gameCloseTime}
        onEdit={onEdit}
        onSave={onSave}
        changeScreen={changeScreen}
        activeTab={gameState.tag}
        openHistory={openHistory}
        previousTab={previousTab}
        disablePayoutTable={!eventsStarted}
        isPooledPrizes={isPooledPrizes}
      />
    );
  }

  if (gameState.tag === 'confirmed') {
    const onEdit = () => {
      setGameState({ ...gameState, userClickedEdit: true });
    };

    return (
      <Screen.Confirmed
        nextMatchTime={gameState.firstEventStartTime}
        confirmedPicks={gameState.confirmedPicks}
        showEditButton={gameState.isEditEnabled}
        onEdit={onEdit}
        openHistory={openHistory}
        activeTab={gameState.tag}
        previousTab={previousTab}
        changeScreen={changeScreen}
        disablePayoutTable={!eventsStarted}
        isPooledPrizes={isPooledPrizes}
        descendingPrizes={gameState.descendingPrizes}
      />
    );
  }

  if (gameState.tag === 'upsell') {
    const onEdit = () => {
      setGameState({ ...gameState, userClickedEdit: true });
    };

    return (
      <Screen.Upsell
        userCurrency={gameState.userCurrency}
        confirmedPicks={gameState.confirmedPicks}
        events={gameState.events}
        nextMatchTime={gameState.firstEventStartTime}
        showEditButton={gameState.isEditEnabled}
        onEdit={onEdit}
        openHistory={openHistory}
        activeTab={gameState.tag}
        previousTab={previousTab}
        changeScreen={changeScreen}
        disablePayoutTable={!eventsStarted}
        isPooledPrizes={isPooledPrizes}
        descendingPrizes={gameState.descendingPrizes}
      />
    );
  }

  if (gameState.tag === 'results') {
    return (
      <Screen.Results
        gameResult={gameState.gameResult}
        pickResults={gameState.pickResults}
        descendingPrizes={gameState.prizeData}
        openHistory={openHistory}
        changeScreen={changeScreen}
        activeTab={gameState.tag}
        previousTab={previousTab}
        disablePayoutTable={!eventsStarted}
        isPooledPrizes={isPooledPrizes}
      />
    );
  }

  return null;
};

export default AppController;
