import { useEffect, useState } from 'react';

export const useDeviceDetect = () => {
  const [isTouchDevice, setIsTouchDevice] = useState(
    window.matchMedia ? window.matchMedia('(pointer:coarse)').matches : false,
  );

  const handleWindowSizeChange = () => {
    if (!window.matchMedia) return;
    const checkTouchDevice = window.matchMedia('(pointer:coarse)').matches;
    if (checkTouchDevice !== isTouchDevice) setIsTouchDevice(checkTouchDevice);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  });
  return isTouchDevice;
};
