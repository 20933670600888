import React, { Suspense } from 'react';

import loader from './loader';
import { ReactComponent as DefaultFootballStrip } from './png/FB/defaults/Football.svg';

import './Strip.scss';

interface StripProps {
  teamCode: string;
  className?: string;
  feedStripURL?: string;
}

const Strip: React.FC<StripProps> = ({ teamCode, className, feedStripURL }) => {
  const isUpsellPage: boolean = className === 'l-upsell-pick__team-strip';
  if (feedStripURL) {
    // UPSELL PAGE AND URL
    if (isUpsellPage) {
      return (
        <div className={className}>
          <img src={feedStripURL} className={className} alt='Team Strip' />
        </div>
      );
    } else {
      // PICK PAGE AND URL
      return (
        <div className={'l-strip-container'}>
          <img src={feedStripURL} className={'l-strip'} alt='Team Strip' />
        </div>
      );
    }
  }

  const defaultFootballStrip = <DefaultFootballStrip className={className} />;
  const Strip = loader(teamCode, 'FB');
  // UPSELL PAGE AND NO URL
  if (isUpsellPage && Strip) {
    return (
      <div className={className}>
        <Suspense fallback={defaultFootballStrip}>
          <Strip className={className} />
        </Suspense>
      </div>
    );
  }

  // PICK PAGE AND NO URL
  if (Strip) {
    return (
      <div className={`l-strip-container ${className ? 'l-strip-container--confirmed-pick' : ''}`}>
        <Suspense fallback={defaultFootballStrip}>
          <Strip className={`l-strip ${className ?? ''}`} />
        </Suspense>
      </div>
    );
  }

  // DEFAULT
  return (
    <div className={'l-strip-container'}>
      <div className={'l-strip'}>{defaultFootballStrip}</div>
    </div>
  );
};

export default Strip;
