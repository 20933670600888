import React from 'react';
import { useTranslation } from 'react-i18next';

import { formatCurrency } from '../../formatting';
import './DisplayCurrency.scss';

interface DisplayCurrencyProps {
  value: number;
  currency: string;
  className?: string;
  largeStyle?: string;
}

export const DisplayCurrency: React.FC<DisplayCurrencyProps> = (props) => {
  const { i18n } = useTranslation();

  const formattedValue = formatCurrency(i18n.language, props.currency, props.value);

  const className = props.className ?? '';
  const largeStyle = props.largeStyle && isLargeCurrency(formattedValue) ? props.largeStyle : '';

  return (
    <div className={`currency ${className} ${largeStyle}`}>
      {props.value === 0 ? '-' : <span>{formattedValue}</span>}
    </div>
  );
};

const isLargeCurrency = (formattedCurrency: string) => {
  if (formattedCurrency.length === 0) return false;
  const length = formattedCurrency.replace(/\D/g, '').length;
  return length > 7;
};

export default DisplayCurrency;
