import React from 'react';
import { useTranslation } from 'react-i18next';

import { GameResultState, GameWon, PickResultState } from 'controller/GameState.types';
import { ReactComponent as WinnerMessage } from '../../../../assets/icons/winnerMessage.svg';
import DisplayCurrency from '../../../../components/DisplayCurrency/DisplayCurrency';
import Logo from '../../../../components/Logo/Logo';
import './ResultHeader.scss';

/*========= Game Progress ==========*/

interface GameProgressProps {
  pickStates: PickResultState[];
  gameResult: GameResultState;
}

export const ResultHeader: React.FC<GameProgressProps> = ({ pickStates, gameResult }) => {
  const correctPicks = getCorrectPicks(pickStates);
  const completedMatches = getCompletedMatches(pickStates);
  const remainingMatches = getRemainingMatches(pickStates);

  // In Progress Game State
  if (gameResult.tag === 'in-progress')
    return (
      <Logo theme='dark-numbers'>
        <InProgressView
          correctPicks={correctPicks}
          completedMatches={completedMatches}
          remainingMatches={remainingMatches}
        />
      </Logo>
    );

  const hasWonNoMerch = gameResult.tag === 'won' && gameResult?.prize?.prizeType !== 'merch';
  return (
    <Logo className={`l-game-progress-logo-${hasWonNoMerch ? gameResult.tag : ''}`} theme='dark-numbers'>
      <CompletedView correctPicks={correctPicks} completedMatches={completedMatches} />
      {hasWonNoMerch && (
        <PrizeView
          prizeCurrency={(gameResult as GameWon).prize.currency}
          prizeValue={(gameResult as GameWon).prize.value}
        />
      )}
    </Logo>
  );
};

/*========= Functions =========*/

/**
 * Calc total picks the user has correctly predicted
 */
export const getCorrectPicks = (pickStates: PickResultState[]) => {
  return pickStates.filter((state) => state.tag === 'won').length;
};

/**
 * Calc total matches that are still to play
 */
export const getRemainingMatches = (pickStates: PickResultState[]) => {
  return pickStates.filter((state) => state.tag === 'in-progress').length;
};

/**
 * Calc total matches that have completed
 */
export const getCompletedMatches = (pickStates: PickResultState[]) => {
  return pickStates.filter((state) => state.tag === 'won' || state.tag === 'lost').length;
};

/*========= Game In Progress Display ==========*/

interface InProgressProps {
  correctPicks: number;
  completedMatches: number;
  remainingMatches: number;
}

export const InProgressView: React.FC<InProgressProps> = (props) => {
  const { t } = useTranslation();

  return (
    <div className='l-result-header l-result-header--in-progress'>
      <div className='summary'>
        <span className='correct-picks'>{props.correctPicks}</span>
        <div className='total-games'>
          <div>{t('result-header-summary-games', { length: props.completedMatches })}</div>
          <div className='total-games-subtext'>{t('result-header-summary-correct')}</div>
        </div>
      </div>
      <div className='round-status'>{t('result-header-round-in-progess')}</div>
      <div className='message'>
        {props.remainingMatches}
        {props.remainingMatches === 1 ? t('result-header-match-remaining') : t('result-header-matches-remaining')}
      </div>
    </div>
  );
};

/*========= Game Completed Display ==========*/

interface CompletedProps {
  correctPicks: number;
  completedMatches: number;
}

export const CompletedView: React.FC<CompletedProps> = (props) => {
  const { t } = useTranslation();

  return (
    <div className='l-result-header'>
      <div className='summary'>
        <div className='correct-picks'>{props.correctPicks}</div>
        <div className='total-games'>
          <div>{t('result-header-summary-games', { length: props.completedMatches })}</div>
          <div className='total-games-subtext'>{t('result-header-summary-correct')}</div>
        </div>
      </div>
      <div className='round-status'>{t('result-header-round-complete')}</div>
      <div className='message'>{t('result-header-results-below')}</div>
    </div>
  );
};

/*========= Prize Won Display ==========*/

interface PrizeViewProps {
  prizeValue: number;
  prizeCurrency: string;
}

export const PrizeView: React.FC<PrizeViewProps> = (props) => {
  const { t } = useTranslation();

  return (
    <div>
      <WinnerMessage className='win-message' />
      <div className='l-game-progress-prize'>
        <div className='prize-message'>{t('result-header-prize-header')}</div>
        <div className='prize-amount'>
          <DisplayCurrency value={props.prizeValue} currency={props.prizeCurrency} />
        </div>
      </div>
    </div>
  );
};
