import React from 'react';
import { useTranslation } from 'react-i18next';

import { PrizeData } from 'controller/GameState.types';
import { ReactComponent as Strip } from '../../../assets/logo/MysteryKitShirt.svg';
import { ReactComponent as Logo } from '../../../assets/logo/MysteryFootball.svg';

import './ShirtPrize.scss';
import { formatCurrency } from 'theme/formatting';

interface ShirtPrizeProps {
  prize: PrizeData;
}

const ShirtPrize: React.FC<ShirtPrizeProps> = ({ prize }) => {
  const { t, i18n } = useTranslation();
  const rrp: string = formatCurrency(i18n.language, prize.currency, prize.value);
  return (
    <div className='shirt-prize-card'>
      <div className='shirt-prize-card__content'>
        <div className='shirt-prize-card__column--1'>
          <div className='shirt-prize-card__pill'>{t('prize-shirt-additional').toUpperCase()}</div>
          <div className='shirt-prize-card__win'>
            <span>{t('prize-shirt-be-one-of')}</span>
            <div className='shirt-prize-card__win--headline'>{t('prize-shirt-win').toUpperCase()}</div>
          </div>
          <div className='shirt-prize-card__how-to-play'>
            <div className='shirt-prize-card__subtext'>{t('prize-shirt-how-to-enter')}</div>
            <div className='shirt-prize-card__how-to-play--rules'>
              {t('prize-shirt-correctly-text')}
              <span className='shirt-prize-card__how-to-play--rules--bold'>
                {t('prize-shirt-predict-atleast-score')}
              </span>
              {t('prize-shirt-in-this-weeks-round-text')}
            </div>
          </div>
        </div>
        <div className='shirt-prize-card__column--2'>
          <div className='shirt-prize-card__partnership'>
            <div className='shirt-prize-card__partnership-text'>
              <div>{t('prize-shirt-in-partnership')}</div>
              <div className='shirt-prize-card__partnership-text--rrp'>
                {t('prize-shirt-retail-value', { rrp: rrp })}
              </div>
            </div>
            <Logo className='shirt-prize-card__partnership--logo' />
          </div>
          <Strip className='shirt-prize-card__strip' />
        </div>
      </div>
    </div>
  );
};

export default ShirtPrize;
