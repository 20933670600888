import React from 'react';
import { useTranslation } from 'react-i18next';

import { PrizeData } from 'controller/GameState.types';
import DisplayCurrency from '../../components/DisplayCurrency/DisplayCurrency';
import { prizeTypes } from 'constants/prizeConstants';

import './Prizes.scss';
import ShirtPrize from './ShirtPrize/ShirtPrize';

interface PrizesProps {
  descendingPrizes: PrizeData[];
}

/**
 * Take the prize with correct pick count of 5 and put it at the start of the array
 * Everything else should be in descending order (Livescore have specifically asked for this)
 * @param prizes
 */
export const customPrizeSort = (prizes: PrizeData[]) => {
  const fivePickPrizeIndex: number = prizes.findIndex((prize) => prize.correctPickCount === 5);
  let response = [...prizes];
  if (fivePickPrizeIndex < -1) {
    response.unshift(response.splice(fivePickPrizeIndex, 1)[0]);
  }
  return response;
};

const Prizes: React.FC<PrizesProps> = ({ descendingPrizes }) => {
  console.log('descendingPrizes', descendingPrizes);
  const { t } = useTranslation();
  const prizes = customPrizeSort(descendingPrizes);
  const cashPrizeCards = prizes.map((prize, index) => {
    const sharedPrize = prize.sharedPrize && prize.value > 0;

    if (prize.correctPickCount === 1 && prize.prizeType === prizeTypes.merch) return null;

    return (
      <div
        className={`${
          prize.prizeType === prizeTypes.noPrize || prize.value === 0 ? 'l-prizes--non-value' : 'l-prizes__prize-card'
        }`}
        key={`prize-card-` + index}
      >
        <div className='l-prizes__prize-card__text'>
          {t('prize-text-beginning')}
          <span className='l-prizes__prize-card__text--bold'>
            {t('prize-text-middle', { correctScore: prize.correctPickCount })}
          </span>
          {t('prize-text-end')}
        </div>
        <div className={`l-prizes__prize-card__value ${sharedPrize ? 'l-prizes__prize-card__value--shared' : ''}`}>
          {prize.prizeType === prizeTypes.cash && <DisplayCurrency currency={prize.currency} value={prize.value} />}
          {prize.prizeType === prizeTypes.merch && <span>{t('merch-prize-text')}</span>}
        </div>
      </div>
    );
  });

  const merchPrizeCards = prizes.map((prize, index) => {
    if (prize.correctPickCount === 1 && prize.prizeType === prizeTypes.merch) {
      return (
        <div key={`prize-card-` + index}>
          <ShirtPrize prize={prize} />
        </div>
      );
    } else {
      return null;
    }
  });

  return (
    <div id='l-prizes' className='l-prizes'>
      <div className={'l-prizes__prize-cards-container'}>
        {cashPrizeCards}
        <div className='l-prizes__prize-cards__shared'>{t('prizes-shared-amongst-winners')}</div>
        {merchPrizeCards}
      </div>
    </div>
  );
};

export default Prizes;
