import React from 'react';

import { ReactComponent as UpArrow } from 'theme/assets/icons/UpArrow.svg';
import { ReactComponent as DownArrow } from 'theme/assets/icons/DownArrow.svg';

import './ChoicePick.scss';

/*=========== ChoicePick ===========*/

interface ChoicePickProps {
  className?: string;
}

export const ChoicePick: React.FC<ChoicePickProps> = ({ className, children }) => {
  return (
    <>
      <div className={`l-choice-pick ${className}`}>{children}</div>
    </>
  );
};

/*=========== PickInput ===========*/
interface PickInputProps {
  value: number;
  onIncrement?: () => void;
  onDecrement?: () => void;
}

export const PickInput: React.FC<PickInputProps> = (props) => {
  return (
    <div className='l-pick-input'>
      <UpArrow className='l-pick-input-arrow l-pick-input-arrow__up' onClick={props.onIncrement} />
      <div className='l-pick-input-value'>{props.value} </div>
      <DownArrow className='l-pick-input-arrow l-pick-input-arrow__down' onClick={props.onDecrement} />
    </div>
  );
};
