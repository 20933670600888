import React from 'react';
import { useTranslation } from 'react-i18next';

import { formatMatchStartDate } from '../../formatting';
import './PickDate.scss';

interface PickDateProps {
  date: number;
}

const PickDate: React.FC<PickDateProps> = ({ date }) => {
  const { i18n } = useTranslation();

  return (
    <div className="l-pick-date">
      <div>{formatMatchStartDate(date, i18n.language)}</div>
    </div>
  );
};

export default PickDate;
