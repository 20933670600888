import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { devTools } from 'common/devtools';
import { formatHistoricGameDate, formatMatchStartDate } from '../../formatting';

type DateFormat = 'match-start' | 'historic-game';

interface DisplayDateProps {
  date: number;
  format: DateFormat;
}

const DisplayDate: React.FC<DisplayDateProps> = ({ date, format }) => {
  const ref = useRef<HTMLSpanElement>(null);

  const { i18n } = useTranslation();

  const language = i18n.language;

  useEffect(() => {
    const string = formatDate(date, language, format);
    if (ref.current) ref.current.innerText = string;
  }, [language, format, date]);

  return <span ref={ref} className={`date-${format}-${language}`} />;
};

const formatDate = (date: number, language: string, format: DateFormat): string => {
  try {
    switch (format) {
      case 'match-start':
        return formatMatchStartDate(date, language);
      case 'historic-game':
        return formatHistoricGameDate(date, language);
    }
  } catch {
    devTools.log('Time Formatting Error');
    return '';
  }
};

export default DisplayDate;
