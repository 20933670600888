import { AsyncResult, isFail } from 'common/result';
import { PredictorEntry, PredictorGame, predictorService } from 'services/predictorService';
import { sportService, SportsEvent } from 'services/sportsService';
import { ErrorState } from './GameState.types';
import { success, fail } from 'common/result';
import { calcErrorState } from './GameState.funcs';
import * as gameFunc from './Game.func';
import config from 'theme/config';

/*============ CONTROLLER TYPES ============*/

// Data required to enter the logged-in app controller
export interface AppControllerData {
  tag: 'app-controller';
  token: string;
  game: PredictorGame;
  events: SportsEvent[];
  initialEntry?: PredictorEntry;
  predictorStats: any;
}

// Data required to enter the no token app controller
export interface NoTokenControllerData {
  tag: 'no-token-controller';
  game: PredictorGame;
  events: SportsEvent[];
}

// Type which will indicate which controller will run the game
export type ControllerData = AppControllerData | NoTokenControllerData;

/*============ REQUEST OF INITIAL GAME DATA ============*/

export type GameDataFunc = (
  userToken: string | undefined,
  predictorId?: number,
  currencyCode?: string,
  countryCode?: string,
  regionCode?: string,
) => AsyncResult<ControllerData, ErrorState>;

/**
 * Launch sequence of the game which will decide which controller will run the game.
 * This involves assess if a user token is present, finding the current game,
 * associated events, and associated user entry.
 */
export const retrieveInitialGameData: GameDataFunc = async (
  userToken,
  predictorId,
  currencyCode,
  countryCode = '',
  regionCode = '',
) => {
  // Request Predictor Games
  const gameRequest = await predictorService.getCurrentGames(countryCode, regionCode);

  // Handle API Error
  if (isFail(gameRequest)) return fail(calcErrorState(gameRequest.value));

  const game = gameFunc.findCurrentGame(gameRequest.value, predictorId);

  // Handle no game available
  if (!game) return fail({ tag: 'error', errorType: 'game-unavailable' });

  let predictorStats;
  if (config.settings.poolPrizes) {
    // Request Predictor Stats
    predictorStats = await predictorService.getPredictorStats({
      predictorId: game.predictorId,
      countryCode,
      countryRegion: regionCode,
      currencyCode,
    });
  }

  // Get Game Events
  const eventsRequest = await sportService.getGameEvents(game.eventGroupId);

  if (isFail(eventsRequest)) return fail(calcErrorState(eventsRequest.value));

  const events = eventsRequest.value;

  // If no token present, enter no token controller
  if (!userToken)
    return success({
      tag: 'no-token-controller',
      game,
      events,
      predictorStats,
    });

  // Token present, request user game entry
  const entryRequest = await predictorService.getPredictorEntry(userToken, game.predictorId);

  // Handle API Error
  if (isFail(entryRequest)) return fail(calcErrorState(entryRequest.value));

  const initialEntry = entryRequest.value;

  // If successful requests and token present, use app controller
  return success({
    tag: 'app-controller',
    token: userToken,
    game,
    events,
    initialEntry,
    predictorStats,
  });
};
