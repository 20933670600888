import { PredictorEntry, PredictorEntryPick, PredictorUpdatedPick } from 'services/predictorService';
import { SportsEvent } from 'services/sportsService';
import {
  GameResultState,
  HistoricGameData,
  HistoricPrize,
  PickData,
  PickResultData,
  PickResultState,
  PrizeData,
  ResultPrize,
} from './GameState.types';
import { mapPrizeCode } from './Game.func';
import { none, Option, some } from 'common/option';
import * as eventFunc from './Event.func';
import { generateTeamStripURL } from './Event.func';

/**
 * For consistent display of picks, order from lowest to highest eventStartTime
 */
export const orderPicksByEventStart = (entryPicks: readonly PredictorEntryPick[]): readonly PredictorEntryPick[] => {
  if (entryPicks.length === 0 || entryPicks.length === 1) return entryPicks;
  return [...entryPicks].sort((a, b) => {
    let compareEventStartTime = a.eventStartTime - b.eventStartTime;
    // If eventStartTime is the same for both, compare by eventId.
    if (compareEventStartTime === 0) {
      return a.eventId - b.eventId;
    }
    return compareEventStartTime;
  });
};

/**
 * Convert API Entry data into game state pick format that is used for the pre-result state
 */
export const calcPickDataFromEntryPicks = (entryPicks: readonly PredictorEntryPick[]): PickData[] => {
  const orderedPicks = orderPicksByEventStart(entryPicks);
  return orderedPicks.map((pick, index) => ({
    pickId: index.toString(),
    eventId: pick.eventId,
    eventStartTime: pick.eventStartTime,
    homeTeamName: pick.eventParticipants[0].participantName,
    homeTeamCode: pick.eventParticipants[0].participantCode,
    homeTeamPrediction: pick.homeScore,
    homeTeamFeedStripURL: generateTeamStripURL(pick.eventParticipants[0]),
    awayTeamName: pick.eventParticipants[1].participantName,
    awayTeamCode: pick.eventParticipants[1].participantCode,
    awayTeamPrediction: pick.awayScore,
    awayTeamFeedStripURL: generateTeamStripURL(pick.eventParticipants[1]),
  }));
};

/**
 * Convert API /put Entry response into our game state format
 */
export const calcPickDataFromEntryUpdate = (
  updatedPicks: PredictorUpdatedPick[],
  events: SportsEvent[],
): PickData[] => {
  const picksFromEvents = eventFunc.calcPickDataFromSportEvents(events);

  const picks = picksFromEvents.map((eventPick) => {
    const entryPick = updatedPicks.find((pick) => pick.eventId === eventPick.eventId);

    return {
      ...eventPick,
      homeTeamPrediction: entryPick?.homeScore ?? 0,
      awayTeamPrediction: entryPick?.awayScore ?? 0,
    };
  });

  return picks;
};

/**
 * Convert Entry into Game Result
 */
export const calcGameResultFromEntry = (entry: PredictorEntry, prizeData: PrizeData[]): GameResultState => {
  if (entry.settled === false) {
    const noPrize: PrizeData = {
      value: 0,
      currency: entry.currencyCode,
      sharedPrize: false,
      correctPickCount: 0,
      prizeType: 'noprize',
    };

    // Assess how may picks the user currently has correct
    const correctPicks = entry.picks.filter((pick) => pick.points > 0).length;

    // Find the prize for current amount of correct picks
    const currentPrize = prizeData.find((prize) => prize.correctPickCount === correctPicks) ?? noPrize;

    // Calculate the rest of the prizes that could still be won
    const upcomingPrizes = prizeData.filter((prize) => prize.value > 0 && prize.correctPickCount > correctPicks);

    // Find the next winnable prize
    const findSmallestPrize = (prizes: PrizeData[]) => {
      if (prizes.length === 0) return noPrize;
      if (prizes.length === 1) return prizes[0];
      return prizes.reduce((a, b) => (b.correctPickCount <= a.correctPickCount ? b : a));
    };

    const nextPrize = findSmallestPrize(upcomingPrizes);

    // As a safety measure, we don't render a shared top prize in the in-progress state as "won"
    // This prize in usually shared between multiple winners, and we need to wait for the server
    // to calculate the user-to-user returns, we only display this value after the entry has been settled
    const displayPrize =
      correctPicks === entry.picks.length && currentPrize.sharedPrize ? { ...currentPrize, value: 0 } : currentPrize;

    return {
      tag: 'in-progress',
      currentPrize: { value: displayPrize.value, currency: displayPrize.currency, prizeType: displayPrize.prizeType },
      nextPrize: { value: nextPrize.value, currency: nextPrize.currency, prizeType: nextPrize.prizeType },
    };
  }

  // Complete - Prize Won
  const hasReturnsOrPrizes = entry.returns > 0 || (entry.prizes?.length ?? 0) > 0;

  if (hasReturnsOrPrizes) {
    const resultPrize: ResultPrize = {
      value: calculatePrizeValue({
        entry,
      }),
      currency: entry.currencyCode,
    };

    const entryPrizeIsFreespinOrMerch =
      entry.prizes &&
      entry.prizes.length > 0 &&
      (entry.prizes[0].prizeCode === 'freespin' || entry.prizes[0].prizeCode === 'merch');
    if (entry.prizes && entry.prizes.length > 0) {
      resultPrize.settled = entry.prizes[0].prizeSettled;
    }

    if (entryPrizeIsFreespinOrMerch) {
      resultPrize.prizeType = mapPrizeCode(entry.prizes[0].prizeCode);
    }

    const wonPrize: GameResultState = {
      tag: 'won',
      prize: resultPrize,
    };

    return wonPrize;
  }

  // Complete - No Prize
  return {
    tag: 'lost',
  };
};

export const calculatePrizeValue = ({ entry }: { entry: PredictorEntry }): number => {
  const { prizes, returns } = entry;
  const prize = prizes?.[0];

  if (prize?.prizeCode === 'freespin') {
    return prize.prizeMultiplier;
  }

  if (prize?.prizeAmount) {
    return prize.prizeAmount;
  }

  return returns;
};

/**
 * Convert Entry Pick into Pick Results
 */
export const calcPickResults = (entry: PredictorEntry, events: SportsEvent[]): PickResultData[] => {
  const calcPickResultState = (pick: PredictorEntryPick): PickResultState => {
    // Pick Resulted
    if (pick.resulted === true) {
      // Resulted without full set of final scores, then suspended match (see support process)
      if (pick.eventHomeScore === null || pick.eventAwayScore === null) return { tag: 'suspended' };

      // Points decide if win/lost for the pick
      if (pick.points > 0) return { tag: 'won' };
      else return { tag: 'lost', homeTeamResult: pick.eventHomeScore, awayTeamResult: pick.eventAwayScore };
    }

    // Pick Unresulted
    return { tag: 'in-progress', eventStartTime: pick.eventStartTime };
  };

  const orderedPicks = orderPicksByEventStart(entry.picks);

  const pickResults: PickResultData[] = orderedPicks.map((pick) => ({
    eventStartTime: pick.eventStartTime,
    homeTeamName: pick.eventParticipants[0].participantName,
    homeTeamCode: pick.eventParticipants[0].participantCode,
    homeTeamPrediction: pick.homeScore,
    homeTeamFeedStripURL: generateTeamStripURL(pick.eventParticipants[0]),
    awayTeamName: pick.eventParticipants[1].participantName,
    awayTeamCode: pick.eventParticipants[1].participantCode,
    awayTeamFeedStripURL: generateTeamStripURL(pick.eventParticipants[1]),
    awayTeamPrediction: pick.awayScore,
    state: calcPickResultState(pick),
    eventStatus: eventFunc.calcEventStatus(pick.eventId, events),
  }));

  return pickResults;
};

/**
 * Convert Entry into Historic Game Result
 */
export const calcHistoricGameFromEntry = (entry: PredictorEntry): HistoricGameData => {
  // No live event data needed for picks display in history
  const picks = calcPickResults(entry, []);
  const prizesExist = entry.prizes?.length ?? 0;
  const prizeCode = entry.prizes?.[0]?.prizeCode;

  // User Won Prize
  const prize: Option<HistoricPrize> =
    entry.returns > 0 || prizesExist
      ? some({
          value: calculatePrizeValue({ entry }),
          currency: entry.currencyCode,
          prizeType: mapPrizeCode(prizeCode),
        })
      : none;

  return {
    settled: entry.settled,
    gameDate: entry.entryTime,
    prizeWon: prize,
    pickResults: picks,
  };
};
