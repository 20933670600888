import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { communicationUtilities } from '@incentivegames/ig-frontend-common/lib/utilities/communicationUtilities';
import { UserContext } from '@incentivegames/ig-frontend-common/lib/contexts/userContext';

import AppControllerNoToken from './AppControllerNoToken';
import AppController from './AppController';
import Screen from 'screens';
import { devTools } from 'common/devtools';
import { ErrorState, HistoryModalState } from './GameState.types';
import { isSuccess } from 'common/result';
import * as gi from './AppInit.funcs';
import * as gs from './GameState.funcs';
import config from 'theme/config';
import './AppController.scss';

const MAX_GAME_REFRESH_TIMEOUT = 2147483647;

/**
 * This component is responsible for retrieving the initial user config, game data
 * and refreshing the game when a game closes or expires.
 */
const AppInit: React.FC = () => {
  /*========== USER CONFIG ===========*/

  const userContext = useContext(UserContext);
  const userToken = userContext.user.token;
  const userLanguage = userContext.user.lang ?? config.settings.defaultLanguage;
  const userCurrency = userContext.user.currency ?? config.settings.defaultCurrency;
  let userCountryCode = userContext.user.countryCode;
  const userRegionCode = userContext.user.regionCode;

  if (config.settings.defaultCountryCode && (!userContext.user.countryCode || userContext.user.countryCode === '')) {
    userCountryCode = config.settings.defaultCountryCode;
  }

  const { i18n } = useTranslation();

  /*========== DEV LOGGING ===========*/

  useEffect(() => {
    devTools.log(
      'Using token:',
      userToken,
      'Using site id:',
      process.env.REACT_APP_SITE_ID,
      'Using context language:',
      userLanguage,
      'i18n language:',
      i18n.language,
      'Using currency:',
      userCurrency,
      'countryCode:',
      userCountryCode,
      'regionCode:',
      userRegionCode,
    );
  }, [userToken, userLanguage, userCurrency, i18n, userCountryCode, userRegionCode]);

  useEffect(() => {
    devTools.log('Game Loaded');
  }, []);

  /*========== INITIAL GAME DATA REQUESTS ===========*/

  const [gameData, setGameData] = useState<gi.ControllerData | undefined>(undefined);
  const [errorState, setErrorState] = useState<ErrorState | undefined>(undefined);

  // The first API requests of the game when a user token is present
  // It will re-trigger if the gameData is cleared
  // If successful, this will lead us into the main AppController
  useEffect(() => {
    const requestGameData = async (token: string) => {
      // With dev tools enabled, we can request a specific game to play
      const gameData = await gi.retrieveInitialGameData(
        token,
        devTools.getPredictorIdViaUrl(),
        userCurrency,
        userCountryCode,
        userRegionCode,
      );
      devTools.log(gameData);
      if (isSuccess(gameData)) setGameData(gameData.value);
      else setErrorState(gameData.value);
    };

    if (userToken && !gameData) requestGameData(userToken);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken, gameData, userCountryCode, userRegionCode]);

  // The first API requests of the game when no user token is present
  // It will re-trigger if the gameData is cleared
  // If successful, this will lead us into the AppControllerNoToken
  useEffect(() => {
    if (!config.controller.allowEnterOnNoToken) return;
    const requestGameData = async () => {
      // If token exists when timeout triggered, we do not make the request
      if (userToken || gameData) return;

      // With dev tools enabled, we can request a specific game to play
      const initialData = await gi.retrieveInitialGameData(
        userToken,
        devTools.getPredictorIdViaUrl(),
        userCurrency,
        userCountryCode,
        userRegionCode,
      );
      devTools.log('initialData', initialData);
      if (isSuccess(initialData)) setGameData(initialData.value);
      else setErrorState(initialData.value);
    };

    // We wait for a set period confirm no token
    // received before advancing the game
    const timeout = setTimeout(requestGameData, 1250);

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken, gameData, userCountryCode, userRegionCode]);

  /*========== CLOSED GAME REFRESH ===========*/

  // If an open game closes while user is in the game,
  // We reset the state machine to bring the user back into the game in a new state.
  useEffect(() => {
    if (!gameData) return;

    const now = Date.now();
    const gameCloseTime = gameData.game.closeTime;
    const timeoutDelay = Math.min(gameCloseTime - now, MAX_GAME_REFRESH_TIMEOUT);

    if (gameCloseTime <= now) {
      if (config.upsell.sendUpsellPostMessage) {
        devTools.log('Sending tracking post message with gameClosed');
        communicationUtilities.sendPostMessage.tracking('gameClosed');
      }
      return;
    }
    const timeout = setTimeout(() => setGameData(undefined), timeoutDelay);
    devTools.log('Open Game Closing refresh in seconds:', timeoutDelay / 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [gameData]);

  // If an closed game passes its displayed time while user is in the game,
  // We reset the state machine to bring the user back into the game in a new state.
  useEffect(() => {
    if (!gameData) return;

    const now = Date.now();
    const gameDisplayEndTime = gameData.game.displayEndTime;
    const timeoutDelay = Math.min(gameDisplayEndTime - now, MAX_GAME_REFRESH_TIMEOUT);

    if (gameDisplayEndTime <= now) return;
    const timeout = setTimeout(() => setGameData(undefined), timeoutDelay);
    devTools.log('Closed Game Expiring refresh in seconds:', timeoutDelay / 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [gameData]);

  /*========== TOKEN REFRESH ==========*/

  // If we are in token controller, and new token is then provided (or token removed), reload game
  useEffect(() => {
    if (gameData && gameData.tag === 'app-controller' && userToken !== gameData.token) {
      devTools.log('Reloading game with new token', userToken);
      setGameData(undefined);
    }
  }, [userToken, gameData]);

  // If we are in no-token controller, and token is then provided, reload game
  useEffect(() => {
    if (gameData && gameData.tag === 'no-token-controller' && userToken) {
      devTools.log('Reloading game with newly present token', userToken);
      setGameData(undefined);
    }
  }, [userToken, gameData]);

  /*========== HISTORY MODAL ===========*/

  const [history, setHistory] = useState<HistoryModalState>({ open: false, status: 'loading', games: [] });
  const openHistory = useCallback(() => setHistory({ ...history, open: true }), [history]);
  const closeHistory = useCallback(() => setHistory({ open: false, status: 'loading', games: [] }), []);

  useEffect(() => {
    const requestHistory = async () => {
      if (userToken) {
        const nextState = await gs.loadEntryHistory(userToken, history);
        setHistory(nextState);
      }
    };

    if (history.open && history.status === 'loading') {
      requestHistory();
    }
  }, [userToken, history]);

  /*========== RENDER LOGIC ===========*/

  if (errorState)
    return (
      <div className='game-root'>
        <Screen.HistoryModal state={history} closeHistory={closeHistory} viewHistoricGame={() => {}} />
        <Screen.Error userLoggedIn={userToken !== undefined} errorState={errorState} openHistory={openHistory} />
      </div>
    );

  // FIXME Loading indefinitely
  if (!gameData) return <Screen.Loading isInitialLoad={true} />;

  // Branch into controller based on result of initial game data request result
  switch (gameData.tag) {
    case 'app-controller':
      return (
        <AppController
          userToken={gameData.token}
          userCurrency={userCurrency}
          userCountry={userCountryCode}
          userRegion={userRegionCode}
          currentGame={gameData.game}
          events={gameData.events}
          initialEntry={gameData.initialEntry}
          history={history}
          openHistory={openHistory}
          closeHistory={closeHistory}
          predictorStats={gameData.predictorStats?.value}
        />
      );
    case 'no-token-controller':
      return <AppControllerNoToken userCurrency={userCurrency} currentGame={gameData.game} events={gameData.events} />;
  }
};

export default AppInit;
