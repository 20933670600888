import { PredictorGame, PredictorPrize } from 'services/predictorService';
import { PrizeData, PrizeType } from './GameState.types';
import { prizeTypes } from 'constants/prizeConstants';

// Map the prizecode to a prizeType
export const mapPrizeCode = (prizeCode: string | undefined): PrizeType => {
  switch (prizeCode) {
    case prizeTypes.betCredit:
      return prizeTypes.betCredit;
    case prizeTypes.cash:
      return prizeTypes.cash;
    case prizeTypes.merch:
      return prizeTypes.merch;
    case prizeTypes.freespin:
      return prizeTypes.freespin;
    default:
      return prizeTypes.noPrize;
  }
};

export const findCurrentGame = (games: PredictorGame[], predictorId?: number): PredictorGame | undefined => {
  // When development tools enabled, we can target a specific game via a URL param
  if (predictorId) return games.find((game) => game.predictorId === predictorId);

  if (games.length === 0) return undefined;
  if (games.length === 1) return games[0];

  const now = Date.now();

  const openGames = games.filter((game) => now >= game.openTime && now < game.closeTime);
  const closedGames = games.filter((game) => now > game.closeTime);

  const nextClosingOpenGame =
    openGames.length > 0
      ? openGames.reduce((prevGame, nextGame) => {
          return prevGame.closeTime <= nextGame.closeTime ? prevGame : nextGame;
        })
      : undefined;

  const lastClosedGame =
    closedGames.length > 0
      ? closedGames.reduce((prevGame, nextGame) => {
          return prevGame.closeTime >= nextGame.closeTime ? prevGame : nextGame;
        })
      : undefined;

  // We display the last closed game until its displayEndTime time has passed
  if (lastClosedGame && lastClosedGame.displayEndTime > now) return lastClosedGame;
  else return nextClosingOpenGame;
};

/**
 * Use prize data from game to calculate
 */
export const calcPrizeData = (
  currency: string,
  prizes: readonly PredictorPrize[],
  totalEvents: number,
): PrizeData[] => {
  // Filter to only have prizes from game object with target currency
  const prizesWithCurrency = prizes.filter((prize) => prize.prizeCurrencyCode.toLowerCase() === currency.toLowerCase());

  // Create an array containing the [correct pick count, game prize position]
  const prizePositions = Array.from(Array(totalEvents)).map((_, i) => [totalEvents - i, i + 1]);

  const outputPrizes: PrizeData[] = prizePositions.map(([correctPickCount, prizePosition]) => {
    const gamePrize = prizesWithCurrency.find(
      (gamePrize) => prizePosition >= gamePrize.prizeMinPosition && prizePosition <= gamePrize.prizeMaxPosition,
    );

    const prizeData: PrizeData = {
      currency: currency,
      correctPickCount: correctPickCount,
      value: gamePrize ? (gamePrize.prizeCode === 'freespin' ? gamePrize.prizeMultiplier : gamePrize.prizeAmount) : 0,
      sharedPrize: gamePrize ? gamePrize.prizeSplit : false,
      prizeType: gamePrize ? mapPrizeCode(gamePrize.prizeCode) : 'noprize',
    };

    return prizeData;
  });

  return outputPrizes;
};
