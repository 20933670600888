import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { communicationUtilities } from '@incentivegames/ig-frontend-common/lib/utilities/communicationUtilities';

import { HistoryModalProps } from 'screens/Screen.types';
import { ReactComponent as DropdownIcon } from '../../assets/icons/DropdownArrow.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/Close.svg';
import { HistoricGameData, HistoricPrize, HistoryModalState } from 'controller/GameState.types';
import { isNone, Some } from 'common/option';
import DisplayDate from '../../components/DisplayDate/DisplayDate';
import LoadingIcon from '../../components/LoadingIcon/LoadingIcon';
import ResultPick from '../../components/ResultPick/ResultPick';
import { devTools } from 'common/devtools';
import './HistoryModal.scss';

/*=========== HISTORY MODAL ===========*/

const HistoryModal: React.FC<HistoryModalProps> = ({ state, closeHistory, viewHistoricGame }) => {
  const { t } = useTranslation();
  const historyModalRef = useRef<HTMLDivElement>(null);
  const bodyHeight = document.body.scrollHeight;

  useEffect(() => {
    const popupHeight = historyModalRef.current?.scrollHeight || 0;
    if (state.open && popupHeight > bodyHeight) {
      devTools.log('Sending resize upon opening popup', popupHeight);
      communicationUtilities.sendPostMessage.resize(popupHeight);
    }
  });

  if (!state.open) return null;

  const renderContent = () => {
    // Error
    if (state.status === 'error') return <div className='history-modal-message'>{t('history-modal-error')}</div>;

    // Loading
    if (state.status === 'loading')
      return (
        <div className='history-modal-message'>
          <LoadingIcon />
        </div>
      );

    // Loaded
    return <GameDisplay state={state} viewHistoricGame={viewHistoricGame} />;
  };

  const onCloseHistory = () => {
    if ((historyModalRef.current?.scrollHeight || 0) > bodyHeight) {
      devTools.log('Sending resize message upon closing history popup', bodyHeight);
      communicationUtilities.sendPostMessage.resize(bodyHeight);
    }
    closeHistory();
    return;
  };

  return (
    <>
      <div className='history-modal-overlay' />
      <div className='history-modal-position'>
        <div className='history-modal' ref={historyModalRef}>
          <div className='history-modal-header'>
            <div className='history-modal-header__text'>{t('history-modal-header')}</div>
            <CloseIcon className='history-modal-header__icon' onClick={onCloseHistory} />
          </div>
          <div className='history-modal-content'>{renderContent()}</div>
        </div>
      </div>
    </>
  );
};

interface GameDisplayProps {
  state: HistoryModalState;
  viewHistoricGame: (game: HistoricGameData) => void;
}

const GameDisplay: React.FC<GameDisplayProps> = ({ state, viewHistoricGame }) => {
  const { t } = useTranslation();

  // Attempt to set the first game in the array as selected
  const [selectedGame, setSelectedGame] = useState(() => {
    return state.games.length > 0 ? state.games[0] : undefined;
  });

  // No Game Found
  if (!selectedGame) return <div className='history-modal-message'>{t('history-modal-no-history')}</div>;

  const isMerch = (): boolean => {
    if (selectedGame?.prizeWon) {
      const prize = selectedGame.prizeWon as Some<HistoricPrize>;
      return prize.value?.prizeType === 'merch';
    }
    return false;
  };

  const renderClaimButton = () => {
    if (isNone(selectedGame.prizeWon) || isMerch()) return null;
    return (
      <div className='claim-prize-button' onClick={() => viewHistoricGame(selectedGame)}>
        {t('history-modal-prize-button')}
      </div>
    );
  };

  // Game Found
  return (
    <>
      <div className='history-modal-inputs'>
        <DropDownMenu selectedGame={selectedGame} allGames={state.games} setSelectedGame={setSelectedGame} />
        {renderClaimButton()}
      </div>
      <div className='historic-game'>
        {selectedGame.pickResults.map((pick, index) => (
          <ResultPick key={index} pick={pick} />
        ))}
      </div>
    </>
  );
};

/*=========== SUB COMPONENTS ===========*/

interface DropDownMenuProps {
  selectedGame: HistoricGameData;
  setSelectedGame: (game: HistoricGameData) => void;
  allGames: HistoricGameData[];
}

const DropDownMenu: React.FC<DropDownMenuProps> = ({ selectedGame, setSelectedGame, allGames }) => {
  const [open, setOpen] = useState(false);

  const onOpen = () => setOpen((open) => !open);

  const onSelect = (game: HistoricGameData) => () => {
    setSelectedGame(game);
    setOpen(false);
  };

  const options = allGames.map((game, index) => (
    <div key={index} className='row' onClick={onSelect(game)}>
      <DisplayDate format='historic-game' date={game.gameDate} />
    </div>
  ));

  return (
    <>
      <div className={`history-dropdown ${open ? 'history-dropdown-open' : ''}`}>
        <div className='header' onClick={onOpen}>
          <DisplayDate format='historic-game' date={selectedGame.gameDate} />
          <DropdownIcon className='icon' />
        </div>
        <div className='row-container'>{open && options}</div>
      </div>
    </>
  );
};

export default HistoryModal;
