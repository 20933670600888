import gameConfigurations from '../theme/config';
export const useDevTools = process.env.REACT_APP_ENABLE_DEV_TOOLS === 'true';

export const devTools = {
  log: (message?: any, ...optionalParams: any[]) => {
    if (!useDevTools) return;
    console.log(message, ...optionalParams);
  },

  getPredictorIdViaUrl: () => {
    const predictorId = new URLSearchParams(document.location.search).get(gameConfigurations.predictorIdIdentifier);
    if (predictorId) return parseInt(predictorId);
  },
};
