import axios from 'axios';

/**
 * Use this on calls that require auth. Add the ENV var REACT_APP_SITE_ENV to you local env file.
 * @param token
 */
export const getHeaders = (userToken?: string) => {
  return {
    'Content-Type': 'application/json',
    'X-IG-ExtToken': userToken,
    'X-IG-SiteId': process.env.REACT_APP_SITE_ID,
  };
};

export const predictorProxy = axios.create({
  baseURL: '/predictorapi',
  timeout: parseInt(process.env.REACT_APP_REQUEST_TIMEOUT || '0', 10),
  headers: { 'Content-Type': 'application/json' },
});

export const sportsProxy = axios.create({
  baseURL: '/sportsapi',
  timeout: parseInt(process.env.REACT_APP_REQUEST_TIMEOUT || '0', 10),
  headers: { 'Content-Type': 'application/json' },
});
