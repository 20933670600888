import React from 'react';
import { t } from 'i18next';

import { ResultsScreenProps } from 'screens/Screen.types';
import { ResultHeader } from './components/ResultHeader/ResultHeader';
import PickDate from '../../components/PickDate/PickDate';
import HistoryButton from '../../components/HistoryButton/HistoryButton';
import ResultPick from '../../components/ResultPick/ResultPick';
import Footer from '../../components/Footer/Footer';
import Prizes from '../../components/Prizes/Prizes';

import './ResultsScreen.scss';
import Logo from 'brands/livescore/components/Logo/Logo';

const ResultsScreen: React.FC<ResultsScreenProps> = (props) => {
  const picks = props.pickResults.map((pick, index) => {
    return (
      <React.Fragment key={index}>
        <PickDate date={pick.eventStartTime} />
        <ResultPick pick={pick} />
      </React.Fragment>
    );
  });

  /*========= HOW TO CLAIM RENDER LOGIC ==========*/

  const HowToClaim = () => {
    return (
      <div className='how-to-claim'>
        <div className='how-to-claim__header'>{t('how-to-claim-header')}</div>
        <div className='how-to-claim__subtext how-to-claim__subtext--one'>{t('how-to-claim-subtext-one')}</div>
        <div className='how-to-claim__subtext'>{t('how-to-claim-subtext-two')}</div>
        <div className='how-to-claim__subtext how-to-claim__subtext--bold'>{t('how-to-claim-subtext-three')}</div>
        <div className='how-to-claim__contact-us'>
          {t('how-to-claim-contact-us')}
          <a href={`mailto:${t('how-to-claim-email')}`} className='how-to-claim__email'>
            {t('how-to-claim-email')}
          </a>
        </div>
      </div>
    );
  };

  /*========= RENDER LOGIC ==========*/

  const pickStates = props.pickResults.map((result) => result.state);
  const hasWonNoMerch = props.gameResult?.tag === 'won' && props.gameResult?.prize?.prizeType !== 'merch';

  return (
    <>
      <HistoryButton openHistory={props.openHistory} />
      <Logo theme='dark-numbers'>
        <ResultHeader pickStates={pickStates} gameResult={props.gameResult} />
        <div className='l-results-screen'>
          {hasWonNoMerch && <HowToClaim />}
          {picks}
          {props.descendingPrizes && <Prizes descendingPrizes={props.descendingPrizes} />}
          <Footer />
        </div>
      </Logo>
    </>
  );
};

export default ResultsScreen;
