import { PrizeData } from 'controller/GameState.types';
import { PredictorGame } from 'services/predictorService';

export const isPooledPrize = (descendingPrizes: PrizeData[]) => {
  return descendingPrizes?.find((prize) => prize.sharedPrize === false && prize.prizeType !== 'noprize') ? false : true;
};

export const isGamePooledPrize = (game: PredictorGame, userCurrency: string) => {
  return game.prizes
    ?.filter((prize) => prize.prizeCurrencyCode === userCurrency)
    .find((prize) => prize.prizeSplit === false && prize.prizeCode !== 'noprize')
    ? false
    : true;
};
