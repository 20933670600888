import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import ReactGA from 'react-ga4';

import { UserContextProvider } from '@incentivegames/ig-frontend-common/lib/contexts/UserContextProvider';
import { StorageContextProvider } from '@incentivegames/ig-frontend-common/lib/contexts/StorageContextProvider';
import { ErrorBoundary } from '@incentivegames/ig-component-common/lib/index';
import { GaCategories } from '@incentivegames/ig-types/lib/enums/gaCatagories/gaCategories';

import { gaUtilities } from '@incentivegames/ig-frontend-common/lib/utilities/gaUtilities';
import { cleanErrorString } from 'common/errors';
import i18n from './theme/i18n';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Config from './theme/config';

import './index.scss';

const onError = (error: any, info: any) => {
  try {
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE || '');
  } catch (error) {
    console.log('Error initialising GA', error);
  }

  // The Error and info vars can be both objects and string -
  // - the below logic is so we only extracts strings.
  let errorString = 'react errorBoundary - unset';
  let infoString = 'react errorBoundary - unset ';
  if (typeof error === 'string') errorString = error;
  if (typeof error.stack === 'string') errorString = error.stack;
  if (typeof info === 'string') infoString = info;
  if (typeof info.componentStack === 'string') infoString = info.stack;
  console.error(error, info);
  gaUtilities.recordEventGA(GaCategories.error, cleanErrorString(errorString), cleanErrorString(infoString));
};

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <StorageContextProvider
      storageType={Config.settings.storageType}
      isDevelopmentEnvironment={process.env.REACT_APP_SITE_ENV === 'dev'}
    >
      <UserContextProvider
        config={{
          tokenCookieName: Config.settings.tokenCookieName,
          tokenFromParentType: Config.settings.tokenFromParentType,
          languageCodeIdentifier: Config.settings.languageCodeIdentifier,
          languageFromParentType: Config.settings.languageFromParentType,
          supportedLanguages: Config.settings.supportedLanguages,
          defaultLanguage: Config.settings.defaultLanguage,
          currencyCodeIdentifier: Config.settings.currencyCodeIdentifier,
          currencyFromParentType: Config.settings.currencyFromParentType,
          defaultCurrency: Config.settings.defaultCurrency,
          supportedCurrencies: Config.settings.supportedCurrencies,
          countryCodeFromParentType: Config.settings.countryCodeFromParentType,
          countryCodeIdentifier: Config.settings.countryCodeIdentifier,
          regionCodeFromParentType: Config.settings.regionCodeFromParentType,
          regionCodeIdentifier: Config.settings.regionCodeIdentifier,
        }}
        languageManager={i18n}
      >
        <ErrorBoundary
          onError={onError}
          wrapperBackgroundColor="#fff"
          wrapperMargin="10px"
          messagePadding="5px"
          messageTextAlign="center"
          errorTextColor="black"
          customErrorMessage={'Something has gone wrong, please try again later.'}
        >
          <App />
        </ErrorBoundary>
      </UserContextProvider>
    </StorageContextProvider>
  </I18nextProvider>,
  document.getElementById('root'),
);
serviceWorker.unregister();
