import React from 'react';
import { useTranslation } from 'react-i18next';

import { communicationUtilities } from '@incentivegames/ig-frontend-common/lib/utilities/communicationUtilities';
import { RedirectType } from '@incentivegames/ig-types/lib/enums/communication/redirectType';
import { ReactComponent as WaitIcon } from '../../assets/icons/WaitIcon.svg';
import { WaitScreenProps } from 'screens/Screen.types';
import Prizes from '../../components/Prizes/Prizes';
import Footer from '../../components/Footer/Footer';
import Logo from '../../components/Logo/Logo';
import Countdown from '../../components/Countdown/Countdown';
import HistoryButton from '../../components/HistoryButton/HistoryButton';
import './WaitScreen.scss';

const WaitScreen: React.FC<WaitScreenProps> = (props) => {
  const { t } = useTranslation();

  const renderNextGameText = () => {
    if (props.nextGameOpenTime)
      return (
        <>
          <div className='l-wait-screen__sub-text'>{t('wait-screen-next-game-text')}</div>
          <Countdown className='l-wait-screen__countdown' countdownTargetTime={props.nextGameOpenTime} />
        </>
      );

    return <div className='l-wait-screen__sub-text'>{t('wait-screen-no-next-game-text')}</div>;
  };

  const renderRegisterButton = () => {
    if (props.userLoggedIn) return null;

    return (
      <button
        className='l-button l-wait-screen__register-button'
        onClick={() => communicationUtilities.sendPostMessage.redirect(RedirectType.register)}
      >
        {t('wait-screen-create-account-button')}
      </button>
    );
  };

  return (
    <>
      {props.userLoggedIn && props.openHistory && <HistoryButton openHistory={props.openHistory} />}
      <div className='l-wait-screen'>
        <Logo className='l-wait-screen__logo' theme='dark-numbers'>
          <WaitIcon className='l-wait-screen__icon' />
          <div className='l-wait-screen__header-text'>{t('wait-screen-header-text')}</div>
          {renderNextGameText()}
          <div className='l-wait-screen__main-content'>
            {renderRegisterButton()}
            {props.descendingPrizes && <Prizes descendingPrizes={props.descendingPrizes} />}
            <Footer />
          </div>
        </Logo>
      </div>
    </>
  );
};

export default WaitScreen;
