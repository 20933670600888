import React from 'react';
import { useTranslation } from 'react-i18next';

import { PredictedScore } from '../../screens/ConfirmedScreen/components/ConfirmedPick';
import { PickEventStatus, PickResultData } from 'controller/GameState.types';
import { formatTeamName } from '../../formatting';
import { ReactComponent as PickWin } from '../../assets/icons/PickWin.svg';
import TeamContainer from '../TeamContainer/TeamContainer';
import './ResultPick.scss';

interface ResultPickProps {
  pick: PickResultData;
}

const ResultPick: React.FC<ResultPickProps> = ({ pick }) => {
  const { t } = useTranslation();

  const inplayScoreMatch = calcInplayScoreMatch(pick.homeTeamPrediction, pick.awayTeamPrediction, pick.eventStatus);
  const wonStyle = pick.state.tag === 'won' || inplayScoreMatch ? 'l-result-pick-won' : '';

  return (
    <div className={`l-result-pick ${wonStyle}`}>
      <TeamContainer
        teamName={formatTeamName(t, pick.homeTeamCode, pick.homeTeamName)}
        teamCode={pick.homeTeamCode}
        feedStripURL={pick.homeTeamFeedStripURL}
      />
      <PickResultContent {...pick} />
      <TeamContainer
        teamName={formatTeamName(t, pick.awayTeamCode, pick.awayTeamName)}
        teamCode={pick.awayTeamCode}
        feedStripURL={pick.awayTeamFeedStripURL}
      />
    </div>
  );
};

export const PickResultContent: React.FC<PickResultData> = (pick) => {
  const { t } = useTranslation();

  if (pick.state.tag === 'lost')
    return (
      <div className='content'>
        <div className='prediction-text'>{t('result-pick-pre-match-prediction')}</div>
        <PredictedScore
          homeTeamGoals={pick.homeTeamPrediction}
          awayTeamGoals={pick.awayTeamPrediction}
          className='lost-result-text'
        />
        <div className='result-period-text'>{t('result-pick-full-time')}</div>
        <div className='result-score-text'>
          {pick.state.homeTeamResult} - {pick.state.awayTeamResult}
        </div>
      </div>
    );

  if (pick.state.tag === 'won')
    return (
      <div className='content'>
        <div className='prediction-text'>{t('result-pick-pre-match-prediction')}</div>
        <PredictedScore homeTeamGoals={pick.homeTeamPrediction} awayTeamGoals={pick.awayTeamPrediction} />
        <PickWin className='win-icon' />
      </div>
    );

  if (pick.state.tag === 'suspended')
    return (
      <div className='content'>
        <div className='suspended-text'>{t('result-pick-match-suspended')}</div>
      </div>
    );

  if (pick.state.tag === 'in-progress') {
    const renderInplayHeader = (status: PickEventStatus) => {
      switch (status.eventPeriod) {
        case 'H1':
        case 'H2':
          return <div className='result-period-text'>{status.eventClockTime}'</div>;
        case 'FT':
          return <div className='result-period-text'>{t('result-pick-full-time')}</div>;
        case 'HT':
          return <div className='result-period-text'>{t('result-pick-half-time')}</div>;
        default:
          return null;
      }
    };

    const renderInplayScore = (status: PickEventStatus) => {
      if (status.eventPeriod === 'PM') return null;
      return (
        <div className='result-score-text'>
          {status.homeTeamScore} - {status.awayTeamScore}
        </div>
      );
    };

    return (
      <div className='content'>
        <div className='prediction-text'>{t('result-pick-pre-match-prediction')}</div>
        <PredictedScore homeTeamGoals={pick.homeTeamPrediction} awayTeamGoals={pick.awayTeamPrediction} />
        {pick.eventStatus && renderInplayHeader(pick.eventStatus)}
        {pick.eventStatus && renderInplayScore(pick.eventStatus)}
      </div>
    );
  }

  return null;
};

/**
 * Function to calculate if the user's score predictions align
 * with the current scores of an inplay match
 */
export const calcInplayScoreMatch = (homePrediction: number, awayPrediction: number, status?: PickEventStatus) => {
  if (!status) return false;
  if (status.eventPeriod === 'PM') return false;
  return homePrediction === status.homeTeamScore && awayPrediction === status.awayTeamScore;
};

export default ResultPick;
