import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { communicationUtilities } from '@incentivegames/ig-frontend-common/lib/utilities/communicationUtilities';
import os from '@incentivegames/ig-types/lib/constants/os';

import { ChoicePick, PickInput } from './components/ChoicePick';
import { ChoiceScreenProps } from 'screens/Screen.types';
import { TeamSide } from 'controller/GameState.types';
import { formatDateToDay, formatDateToTime, formatTeamName } from '../../formatting';
import Logo from '../../components/Logo/Logo';
import Footer from '../../components/Footer/Footer';
import TeamContainer from '../../components/TeamContainer/TeamContainer';
import Prizes from '../../components/Prizes/Prizes';
import ChoiceHeader from './components/ChoiceHeader';
import ConfirmChoicesModal from './components/ConfirmChoicesModal';
import NumberPicker from 'brands/livescore/components/NumberPicker/NumberPicker';

import './ChoiceScreen.scss';

const ChoiceScreen: React.FC<ChoiceScreenProps> = (props) => {
  const { t, i18n } = useTranslation();

  /*======= CONFIRM MODAL =======*/

  const [showConfirmModal, setShowModal] = useState(false);
  const [operatingSystem, setOperatingSystem] = useState<string>();

  useEffect(() => {
    setOperatingSystem(communicationUtilities.getOs());
  }, []);

  const onClickSave = () => {
    if (props.userLoggedIn) {
      if (props.choiceType === 'edit') {
        setShowModal(true);
      } else {
        props.onSave(); // Logged in - Will submit selections to server and enter loading
      }
    } else {
      props.onSave(); // Logged out - Will save selections to local storage and enter loading
      communicationUtilities.sendPostMessage.login();
    }
  };

  const onCancelEdit = () => setShowModal(false);

  /*======= CONFIRM MODAL =======*/

  const onChange = (pickId: string, team: TeamSide, predictionChoice: number) => {
    if (predictionChoice < props.minPickValue || predictionChoice > props.maxPickValue) return;
    props.onEdit(pickId, team, predictionChoice);
  };

  const picks = props.editablePicks.map((pick) => {
    return (
      <React.Fragment key={pick.pickId}>
        <ChoicePick className={`${operatingSystem !== os.WEB ? 'l-choice-pick--mobile' : ''}`}>
          <TeamContainer
            teamName={formatTeamName(t, pick.homeTeamCode, pick.homeTeamName)}
            teamCode={pick.homeTeamCode}
            feedStripURL={pick.homeTeamFeedStripURL}
          />
          <div className='l-pick-input-container'>
            <PickInput
              value={pick.homeTeamPrediction}
              onIncrement={() => onChange(pick.pickId, 'home', pick.homeTeamPrediction + 1)}
              onDecrement={() => onChange(pick.pickId, 'home', pick.homeTeamPrediction - 1)}
            />
            <div className='l-pick-kickoff'>
              <span className='l-pick-kickoff__day'>{formatDateToDay(pick.eventStartTime, i18n.language)}</span>
              <span className='l-pick-kickoff__time'>{formatDateToTime(pick.eventStartTime, i18n.language)}</span>
            </div>
            <PickInput
              value={pick.awayTeamPrediction}
              onIncrement={() => onChange(pick.pickId, 'away', pick.awayTeamPrediction + 1)}
              onDecrement={() => onChange(pick.pickId, 'away', pick.awayTeamPrediction - 1)}
            />
          </div>
          <TeamContainer
            teamName={formatTeamName(t, pick.awayTeamCode, pick.awayTeamName)}
            teamCode={pick.awayTeamCode}
            feedStripURL={pick.awayTeamFeedStripURL}
          />
        </ChoicePick>
      </React.Fragment>
    );
  });

  return (
    <Logo className='l-choice-screen-logo' theme='dark-numbers'>
      <ChoiceHeader
        topPrize={props.descendingPrizes[0]}
        gameCloseTime={props.gameCloseTime}
        userLoggedIn={props.userLoggedIn}
        openHistory={props.openHistory}
      />
      <div className='l-choice-screen'>
        {picks}
        <ConfirmChoicesModal onCancel={onCancelEdit} onSave={props.onSave} open={showConfirmModal} />
        <div className='l-choice-screen__submit-container'>
          <div className='l-choice-screen__submit-container--curved-lines'>
            <button onClick={onClickSave} className=' l-button l-choice-screen__button'>
              {t('choice-screen-save-button')}
            </button>
          </div>
        </div>
        <div className='l-prizes__header'>{t('prizes-header')}</div>
        <Prizes descendingPrizes={props.descendingPrizes} />
        <div className='l-choice-screen__terms-and-conditions'>{t('choice-screen-terms-and-conditions')}</div>
        <Footer />
      </div>
    </Logo>
  );
};

export default ChoiceScreen;
