import React from 'react';
import { useTranslation } from 'react-i18next';

import DisplayCurrency from '../../../components/DisplayCurrency/DisplayCurrency';
import { PrizeData } from 'controller/GameState.types';

import './ChoiceHeader.scss';
import Countdown from '../../../components/Countdown/Countdown';

interface ChoiceHeaderProps {
  topPrize: PrizeData;
  gameCloseTime: number;
  userLoggedIn: boolean;
  openHistory?: () => void;
}

const ChoiceHeader: React.FC<ChoiceHeaderProps> = ({ topPrize, gameCloseTime, userLoggedIn, openHistory }) => {
  const { t } = useTranslation();
  const sharedPrize = topPrize.sharedPrize && topPrize.value > 0;

  const getDescriptionContent = (userLoggedIn: boolean, openHistory?: () => void) => {
    if (!userLoggedIn) {
      return (
        <>
          <div>
            {t('choice-screen-header-description-1', { correctScore: topPrize.correctPickCount })}
            <span className='choice-header__description--bold'>{t('choice-screen-header-description-bold-1')}</span>
            {t('choice-screen-header-description-2')}
          </div>
          {t('choice-screen-header-description-3-logged-out')}
        </>
      );
    }
    return (
      <>
        <div>
          {t('choice-screen-header-description-1', { correctScore: topPrize.correctPickCount })}
          <span className='choice-header__description--bold'>{t('choice-screen-header-description-bold-1')}</span>
          {t('choice-screen-header-description-2')}
        </div>
        <div className='choice-header__description-link' onClick={openHistory}>
          {t('choice-screen-header-history-link')}
        </div>
      </>
    );
  };

  return (
    <div className='choice-header'>
      <div className='choice-header__prize'>
        {t('choice-screen-header-win')}
        <DisplayCurrency
          value={topPrize.value}
          currency={topPrize.currency}
          className={`choice-header__prize--value ${sharedPrize ? 'choice-header__prize--value-shared' : ''}`}
        />
      </div>
      <div className='choice-header__description'>{getDescriptionContent(userLoggedIn, openHistory)}</div>
      <div className='choice-header__terms'>{t('choice-screen-subheader-message')}</div>
      <Countdown countdownTargetTime={gameCloseTime} />
    </div>
  );
};

export default ChoiceHeader;
