import Strip from '../Strip/Strip';
import './TeamContainer.scss';

interface TeamContainerProps {
  teamName: string;
  teamCode: string;
  className?: string;
  feedStripURL?: string;
}

const TeamContainer: React.FC<TeamContainerProps> = ({ className, teamCode, teamName, feedStripURL }) => {
  return (
    <div className='l-team'>
      <Strip teamCode={teamCode} className={`${className ?? ''}`} feedStripURL={feedStripURL} />
      <div className='l-team__name'>{teamName}</div>
    </div>
  );
};

export default TeamContainer;
