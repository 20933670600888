import { useContext, useEffect, useLayoutEffect } from 'react';
import { ResizeObserver } from 'resize-observer';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import Routes from './routes/Routes';
import { UserContext } from '@incentivegames/ig-frontend-common/lib/contexts/userContext';
import { communicationUtilities } from '@incentivegames/ig-frontend-common/lib/utilities/communicationUtilities';
import config from './theme/config';
import './shared.scss';

interface IAppProps {}

const App = (props: IAppProps) => {
  const userContext = useContext(UserContext);
  const { lang } = userContext.user;
  const {
    i18n: { language },
  } = useTranslation();

  useLayoutEffect(() => {
    const root = document.getElementById('root');
    if (root) {
      root.dir = i18n.dir(language);
    }
  }, [language]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      communicationUtilities.sendPostMessage.resize();
    });

    resizeObserver.observe(document.body);

    try {
      ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE || '');
    } catch (error) {
      console.log('Error initialising GA', error);
    }

    // The order of these functions and their values are dependent on function
    // declaration in ig-frontend-common more info on the link below
    // https://github.com/incentivegames/ig-frontend-common/blob/master/src/contexts/userContext.ts#L285
    userContext.user.addConfigHandlers(
      (token: string) => userContext.setToken(token),
      (language: string) => userContext.setLanguage(language),
      (currency: string) => userContext.setCurrency(currency),
      (customerId: string) => userContext.setCustomerId(customerId),
      (countryCode: string) => userContext.setCountryCode(countryCode),
      (regionCode: string) => userContext.setRegionCode(regionCode),
    );

    if (process.env.REACT_APP_SITE_ENV !== 'dev') {
      const root = document.getElementById('root');
      if (root) root.style.overflow = 'hidden';
    }

    // eslint-disable-next-line
  }, []);

  // Game type is added as a css class to allow override of base styles based on game type
  return (
    <div className={`app ${config.gameType}`} lang={lang}>
      <Routes />
    </div>
  );
};

export default App;
