import { TFunction } from 'i18next';

/*============= CURRENCY FORMATTING ============*/

const wholeNumberOptions: Intl.NumberFormatOptions = {
  style: 'currency',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
};

const fractionNumberOptions: Intl.NumberFormatOptions = {
  style: 'currency',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

/**
 * Called for the display of any currency in the game.
 * Outputs currency in parts (£, 10, etc) to allow separate styling of currency symbols, etc.
 */
export const formatCurrency = (language: string, currency: string, value: number): string => {
  const baseOptions = Number.isSafeInteger(value) ? wholeNumberOptions : fractionNumberOptions;
  const options: Intl.NumberFormatOptions = { ...baseOptions, currency: currency };
  const result = new Intl.NumberFormat(language, options).format(value);
  return result;
};

/*============= DATE FORMATTING ============*/

/**
 * Called for the display of match start time in the choice/confirmed/results screens.
 */
export const formatMatchStartDate = (date: number, language: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };

  const dateParts = Intl.DateTimeFormat(language, options).formatToParts(date);

  const day = dateParts.find((part) => part.type === 'day')?.value;
  const weekday = dateParts.find((part) => part.type === 'weekday')?.value;
  const hour = dateParts.find((part) => part.type === 'hour')?.value;
  const minute = dateParts.find((part) => part.type === 'minute')?.value;
  const month = dateParts.find((part) => part.type === 'month')?.value;
  return `${weekday ?? ''} ${day ?? ''} ${month ?? ''} ${hour ?? ''}:${minute ?? ''}`;
};

/**
 * Called for the display of game dates in the historic game modal.
 */
export const formatHistoricGameDate = (date: number, language: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour12: false,
  };

  return Intl.DateTimeFormat(language, options).format(date);
};

/**
 * Called to format the date to the shorthand notation of a weekday (e.g. Mon, Tue, etc).
 */
export const formatDateToDay = (date: number, language: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'short',
  };

  return Intl.DateTimeFormat(language, options).format(date);
};

/**
 * Called to format the date to the 24h notation of time (e.g 14:00, 15:30, etc).
 */
export const formatDateToTime = (date: number, language: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h23',
  };

  return Intl.DateTimeFormat(language, options).format(date);
};

/*============= TEAM NAME FORMATTING ============*/

/**
 * Used when we need brand specific team name adjustment, or mapping of team names
 * into translation keys.
 */
export const formatTeamName = (t: TFunction, teamCode: string, defaultName: string) => {
  return t(`team-name-${teamCode}`, defaultName);
};
