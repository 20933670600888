import React from 'react';
import { useTranslation } from 'react-i18next';
import Strip from '../Strip/Strip';
import './UpsellPick.scss';

export interface IUpsellPickProps {
  homeTeamCode: string;
  awayTeamCode: string;
  homeScore: number;
  awayScore: number;
  homeTeamFeedStripURL?: string;
  awayTeamFeedStripURL?: string;
}

const renderTeam = (
  awayTeam: boolean,
  teamCode: string,
  teamScore: number,
  translatedTeamCode: string,
  feedStripURL?: string,
) => {
  return (
    <>
      <div className={`l-upsell-pick__team-container ${awayTeam ? 'l-upsell-pick__team-container--away-team' : ''}`}>
        <div className={'l-upsell-pick__team-information'}>
          <Strip teamCode={teamCode} className={'l-upsell-pick__team-strip'} feedStripURL={feedStripURL} />
          <div className={`l-upsell-pick__team-code`}>{translatedTeamCode}</div>
        </div>
        <div className={`l-upsell-pick__team-score ${awayTeam ? 'l-upsell-pick__team-score--away-team' : ''}`}>
          {teamScore}
        </div>
      </div>
    </>
  );
};

export const UpsellPick: React.FC<IUpsellPickProps> = (props) => {
  const { t } = useTranslation();
  const translatedHomeTeamCode = t(`team-code-${props.homeTeamCode}`, props.homeTeamCode);
  const translatedAwayTeamCode = t(`team-code-${props.awayTeamCode}`, props.awayTeamCode);

  return (
    <div className='l-upsell-pick'>
      {renderTeam(false, props.homeTeamCode, props.homeScore, translatedHomeTeamCode, props.homeTeamFeedStripURL)}
      <div className={'l-upsell-pick__divider'}>{'-'}</div>
      {renderTeam(true, props.awayTeamCode, props.awayScore, translatedAwayTeamCode, props.awayTeamFeedStripURL)}
    </div>
  );
};
