import React, { Suspense } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';

import { useDevTools } from 'common/devtools';
import AppInit from 'controller/AppInit';

const TestPages = React.lazy(() => import('test/Test'));

const LazyLoadTestPages: React.FC = () => (
  <Suspense fallback={null}>
    <TestPages />
  </Suspense>
);

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={'/'} component={AppInit} />
        {useDevTools && <Route path="/review/" component={LazyLoadTestPages} />}
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
