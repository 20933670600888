import sha1 from 'crypto-js/sha1';
import crypto from 'crypto';

import { UpsellEntry } from '@incentivegames/ig-types/lib/interfaces/apiContracts';
import { communicationUtilities } from '@incentivegames/ig-frontend-common/lib/utilities/communicationUtilities';

import { PredictorEntryPick, PredictorUpdatedPick } from 'services/predictorService';
import { SportsEvent } from 'services/sportsService';
import { devTools } from 'common/devtools';

/**
 * Every user attempt to create/edit their picks get its own unique hash for server identification
 */
export const createUniqueId = (predictorId: number) => {
  const uniqueId = (crypto.randomBytes(25).toString('hex') + predictorId.toString() + Date.now()).toString();
  return sha1(uniqueId).toString();
};

export const postUpsellEntry = (
  events: SportsEvent[],
  picks: readonly PredictorEntryPick[] | readonly PredictorUpdatedPick[],
  receipt: string,
) => {
  const upsellEntry: UpsellEntry = {
    receipt: receipt,
    selections: [],
  };

  picks.forEach((pick) => {
    upsellEntry.selections.push({
      homeTeamCode: pick.eventParticipants?.find((participant) => participant.position === 1)?.participantCode || '',
      awayTeamCode: pick.eventParticipants?.find((participant) => participant.position === 2)?.participantCode || '',
      homeScore: pick.homeScore,
      awayScore: pick.awayScore,
      extEventId: events?.find((event) => event.eventId === pick.eventId)?.extEventId,
    });
  });

  devTools.log('Sending upsell post message with payload: ', upsellEntry);
  communicationUtilities.sendPostMessage.upsell(upsellEntry);
};

export const delay = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
