import { none, Option, some } from 'common/option';
import { SportsEvent, SportsEventParticipant } from 'services/sportsService';
import { EventPeriod, PickData, PickEventStatus } from './GameState.types';
import config from 'theme/config';

export const orderEventsByStartTime = (events: readonly SportsEvent[]): readonly SportsEvent[] => {
  if (events.length === 0 || events.length === 1) return events;
  return [...events].sort((a, b) => {
    let compareEventStartTime = a.eventStartTime - b.eventStartTime;
    // If eventStartTime is the same for both, compare by eventId.
    if (compareEventStartTime === 0) {
      return a.eventId - b.eventId;
    }
    return compareEventStartTime;
  });
};

export const generateTeamStripURL = (eventParticipant: SportsEventParticipant): string | undefined => {
  const relativePath: string | undefined = eventParticipant?.attributes?.find(
    (attribute) => attribute.name === 'badgeImage',
  )?.value;
  return config.settings.baseStripURL && relativePath ? `${config.settings.baseStripURL}${relativePath}` : undefined;
};

/**
 * If you the user has no entry for the game, we generate pick data from the events data.
 * If locally saved pick data (from logged out play) we attempt repopulate the
 * user's previous picks.
 */
export const calcPickDataFromSportEvents = (events: SportsEvent[], localSavedPicks?: PickData[]): PickData[] => {
  // Order events by start time
  const orderedEvents = orderEventsByStartTime(events);

  // Create pick per event
  const picks = orderedEvents.map((event, index) => ({
    pickId: index.toString(),
    eventId: event.eventId,
    eventStartTime: event.eventStartTime,
    homeTeamName: event?.eventParticipants[0]?.participantName,
    homeTeamCode: event?.eventParticipants[0]?.participantCode,
    homeTeamFeedStripURL: generateTeamStripURL(event?.eventParticipants[0]),
    homeTeamPrediction: 0,
    awayTeamName: event?.eventParticipants[1]?.participantName,
    awayTeamCode: event?.eventParticipants[1]?.participantCode,
    awayTeamPrediction: 0,
    awayTeamFeedStripURL: generateTeamStripURL(event?.eventParticipants[1]),
  }));

  // If no local saved pick provided, we start scores 0-0
  if (!localSavedPicks) return picks;

  // If local saved picks, attempt populate scores from local saved picks
  const picksWithLocalChoices = picks.map((pick) => {
    const localPick = localSavedPicks.find((local) => local.eventId === pick.eventId);

    const homeTeamPrediction = localPick ? localPick.homeTeamPrediction : 0;
    const awayTeamPrediction = localPick ? localPick.awayTeamPrediction : 0;

    return {
      ...pick,
      homeTeamPrediction,
      awayTeamPrediction,
    };
  });

  return picksWithLocalChoices;
};

export const findEarliestEventStart = (events: SportsEvent[]): Option<number> => {
  if (events.length === 0) return none;
  if (events.length === 1) return some(events[0].eventStartTime);
  const earliestEvent = events.reduce((a, b) => (a.eventStartTime <= b.eventStartTime ? a : b));
  return some(earliestEvent.eventStartTime);
};

export const mapEventPeriod = (period: string): EventPeriod => {
  switch (period) {
    case 'PM':
      return 'PM';
    case 'H1':
      return 'H1';
    case 'HT':
      return 'HT';
    case 'H2':
      return 'H2';
    case 'FT':
      return 'FT';
    default:
      return 'PM';
  }
};

export const calcEventStatus = (eventId: number, events: SportsEvent[]): PickEventStatus | undefined => {
  const event = events.find((event) => event.eventId === eventId);
  if (!event) return undefined;
  return {
    eventPeriod: mapEventPeriod(event.eventPeriod),
    eventClockTime: event.eventClockTime,
    homeTeamScore: event.eventHomeScore,
    awayTeamScore: event.eventAwayScore,
  };
};
