/*============= SPORT EVENT ==============*/

import { AsyncResult, success } from 'common/result';
import { getHeaders, sportsProxy } from './proxies';
import { APIError, handleAPIError } from './util';
import config from 'theme/config';

export interface MarketSelection {
  readonly marketCode: string;
  readonly extSelectionId: string;
  readonly selectionId: string;
  readonly selectionCode: string;
  readonly selectionName: string;
  readonly price: {
    readonly decimal: number;
    readonly fractional: string;
    readonly american: string;
  };
}

export interface SportEventMarket {
  readonly marketId: string;
  readonly extEventId: string;
  readonly extMarketId: string;
  readonly marketCode: string;
  readonly marketName: string;
  readonly selections: Array<MarketSelection>;
}

export interface SportsEventParticipant {
  readonly participantName: string;
  readonly participantCode: string;
  readonly attributes?: Array<{ name: string; value: string }>;
}

export interface SportsEvent {
  readonly eventId: number;
  readonly eventActive: boolean;
  readonly eventStartTime: number;
  readonly eventParticipants: [SportsEventParticipant, SportsEventParticipant];
  readonly eventPeriod: string;
  readonly eventClockTime: string;
  readonly eventHomeScore: number;
  readonly eventAwayScore: number;
  readonly markets?: Array<SportEventMarket>;
  readonly extEventId?: string;
}

/*============= SPORTS SERVICE ==============*/

export interface SportsService {
  getGameEvents: (eventGroupId: number) => AsyncResult<SportsEvent[], APIError>;
}

export const sportService: SportsService = {
  getGameEvents: async (eventGroupId: number) => {
    try {
      const upsell = config.upsell.showUpsell ? `&marketCode=1X2&feedProvider=${config.upsell.feedProvider}` : '';
      const response = await sportsProxy.get(`/events?eventGroupId=${eventGroupId}` + upsell, {
        headers: getHeaders(),
      });
      return success(response.data.events);
    } catch (error) {
      return fail(handleAPIError(error));
    }
  },
};
