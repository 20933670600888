import React from 'react';
import { useTranslation } from 'react-i18next';

import { ConfirmedPick, PredictedScore } from './components/ConfirmedPick';

import { ConfirmedScreenProps } from 'screens/Screen.types';
import { formatTeamName } from '../../formatting';
import HistoryButton from '../../components/HistoryButton/HistoryButton';
import Footer from '../../components/Footer/Footer';
import PickDate from '../../components/PickDate/PickDate';
import TeamContainer from '../../components/TeamContainer/TeamContainer';
import Logo from '../../components/Logo/Logo';
import ConfirmedHeader from './components/ConfirmedHeader';
import Countdown from '../../components/Countdown/Countdown';
import { isSome } from 'common/option';

import './ConfirmedScreen.scss';

const ConfirmedScreen: React.FC<ConfirmedScreenProps> = (props) => {
  const { t } = useTranslation();

  const picks = props.confirmedPicks.map((pick) => (
    <React.Fragment key={pick.pickId}>
      <PickDate date={pick.eventStartTime} />
      <ConfirmedPick>
        <TeamContainer
          teamName={formatTeamName(t, pick.homeTeamCode, pick.homeTeamName)}
          teamCode={pick.homeTeamCode}
          className='l-strip--confirmed-pick'
          feedStripURL={pick.homeTeamFeedStripURL}
        />
        <PredictedScore homeTeamGoals={pick.homeTeamPrediction} awayTeamGoals={pick.awayTeamPrediction} />
        <TeamContainer
          teamName={formatTeamName(t, pick.awayTeamCode, pick.awayTeamName)}
          teamCode={pick.awayTeamCode}
          className='l-strip--confirmed-pick'
          feedStripURL={pick.awayTeamFeedStripURL}
        />
      </ConfirmedPick>
    </React.Fragment>
  ));

  const renderEditButton = () => {
    if (!props.showEditButton) return null;

    return (
      <button onClick={props.onEdit} className='l-confirmed-screen__edit-button'>
        {t('confirmed-screen-edit-button')}
      </button>
    );
  };

  return (
    <>
      <HistoryButton openHistory={props.openHistory} />
      <Logo className='l-logo--confirmed' theme='dark-numbers'>
        <ConfirmedHeader />
        <div className='l-confirmed-screen'>
          <Countdown countdownTargetTime={isSome(props.nextMatchTime) ? props.nextMatchTime.value : 0} />
          {picks}
          {renderEditButton()}
          <Footer />
        </div>
      </Logo>
    </>
  );
};

export default ConfirmedScreen;
