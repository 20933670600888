import React from 'react';
import './Logo.scss';

interface LogoProps {
  className?: string;
  theme?: 'light' | 'dark' | 'dark-numbers';
}

const Logo: React.FC<LogoProps> = ({ className, theme = 'light', children }) => {
  return <div className={`l-logo l-logo-${theme} ${className ? className : ''}`}>{children}</div>;
};

export default Logo;
