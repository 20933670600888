import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ConfirmedTick } from '../../../assets/icons/PickWin.svg';

import './ConfirmedHeader.scss';

const ConfirmedHeader: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="confirmed-header">
      <ConfirmedTick />
      <div className="confirmed-header__title"> {t('confirmed-header-title')}</div>
      <div className="confirmed-header__sub-text">{t('confirmed-header-sub-text')}</div>
    </div>
  );
};

export default ConfirmedHeader;
