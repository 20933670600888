import { devTools } from 'common/devtools';

/*============= API ERROR ==============*/

export type APIErrorCode = 'invalid-token' | 'internal-error' | 'player-not-found' | 'access-token-expired';

export interface APIError {
  errorCode: APIErrorCode;
  errorMessage: string;
}

export const handleAPIError = (error: any): APIError => {
  devTools.log('API Error', error);

  const errorCode = error?.response?.data?.errorCode;
  const errorMessage = error?.response?.data?.errorMessage;

  if (errorCode === 'invalid-token')
    return {
      errorCode: 'invalid-token',
      errorMessage: 'Invalid Token',
    };
  if (errorCode === 'player-not-found' && errorMessage === 'Access Token has expired') {
    return {
      errorCode: 'access-token-expired',
      errorMessage: 'Access Token has expired',
    };
  }
  if (errorCode === 'player-not-found')
    return {
      errorCode: 'player-not-found',
      errorMessage: 'Error retrieving player',
    };

  return {
    errorCode: 'internal-error',
    errorMessage: 'Error',
  };
};
