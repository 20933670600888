import { StorageType } from '@incentivegames/ig-types/lib/enums/storage/storageType';
import { IGameConfiguration } from 'interfaces/configurationInterfaces';
import InputSource from '@incentivegames/ig-types/lib/constants/communication/inputSource.js';

/**
 * For easier testing/dev, use URL query string as token/currency/language
 * when building app with our internal site id / authentication system.
 *  */
const inputSource = process.env.REACT_APP_SITE_ID === '00000000' ? InputSource.URL : InputSource.POSTMESSAGE;

/**
 * For easier testing/dev, user quicker poll timing when building app
 * with out internal site id / authentication system.
 */
const pollTime = process.env.REACT_APP_SITE_ID === '00000000' ? 3000 : 60000;

const gameConfigurations: IGameConfiguration[] = [
  {
    predictorIdIdentifier: 'predictorId',
    gameType: 'predict6',

    settings: {
      // Token Config
      tokenFromParentType: inputSource,
      tokenCookieName: 'token',

      // Locale Config
      defaultLocale: '',

      // Language Config
      languageFromParentType: inputSource,
      languageCodeIdentifier: 'language',
      defaultLanguage: 'en',
      fallbackLanguage: 'en',
      supportedLanguages: ['en'],

      // Currency Config
      defaultCurrency: 'GBP',
      currencyFromParentType: InputSource.URL,
      currencyCodeIdentifier: 'currencyCode',
      supportedCurrencies: ['GBP', 'EUR'],

      // Storage Config
      storageType: StorageType.localStorage,
      countryCodeFromParentType: InputSource.URL,
      countryCodeIdentifier: 'countryCode',
      defaultCountryCode: 'GB',
      regionCodeFromParentType: InputSource.URL,
      regionCodeIdentifier: 'countryRegion',

      taxNotification: false,
      taxNotificationUrl: '',

      // Brand Feed Config - NOTE: Prod requires content headers to be set to work
      baseStripURL: 'https://lsm-static-prod.livescore.com/high/',
      poolPrizes: false,
      enableUpsellCarousel: false,
    },

    // Controller Config
    controller: {
      allowEnterOnNoToken: true,
      savePicksOnNoToken: true,
      screenOnOpenGameNoEntry: 'choice',
      screenOnOpenGameWithEntry: 'upsell',
      showUnsettledEntriesInHistory: false,
      sendCountryRegionToPredictor: true,
    },

    // Upsell Config
    upsell: {
      showUpsell: true,
      feedProvider: 'livescore',
      sendUpsellPostMessage: true,
    },

    // Results config
    results: {
      pollData: false,
      pollTime: pollTime,
    },
  },
];

export default gameConfigurations[0];
