import React from 'react';
import { TFunction, useTranslation } from 'react-i18next';

import { ErrorScreenProps } from 'screens/Screen.types';
import { ErrorState } from 'controller/GameState.types';
import Footer from 'brands/livescore/components/Footer/Footer';
import WaitScreen from '../../screens/WaitScreen/WaitScreen';

import './ErrorScreen.scss';

const ErrorScreen: React.FC<ErrorScreenProps> = ({ userLoggedIn, errorState, openHistory }) => {
  const { t } = useTranslation();
  const playerNotFoundError = errorState.errorType === 'player-not-found';

  // useEffect(() => {
  //   if (playerNotFoundError) {
  //     ReactGA.send({
  //       hitType: 'pageview',
  //       page: 'player-not-found-error-screen',
  //       title: process.env.REACT_APP_GAME_TYPE,
  //     });
  //   }
  // }, [playerNotFoundError]);

  // It can be expanded if more modifiers need to be used based on
  // the type of the API errors
  const calcErrorScreenClassModifier = () => {
    if (playerNotFoundError) return '--player-not-found';
    return '--generic';
  };

  /**
   * Returns the text that is being rendered on each element based on the
   * type of the provided error
   *
   * @param {TFunction} t the translation function
   * @param {ErrorState} errorState the state of the error
   * @param {string} elementType the type of the element((header, message) which uses the text
   * @returns {string} the actual text of the element
   */
  const renderErrorText = (t: TFunction, errorState: ErrorState, elementType: string) => {
    switch (errorState.errorType) {
      case 'player-not-found':
        return t(`error-screen-${elementType}-player-not-found`);
      default:
        return t(`error-screen-${elementType}-generic`);
    }
  };

  if (errorState.errorType === 'game-unavailable')
    return <WaitScreen openHistory={openHistory} userLoggedIn={userLoggedIn} />;

  return (
    <>
      <div className='l-error-screen-container'>
        <div className={`l-error-screen-header${calcErrorScreenClassModifier()}`}>
          {renderErrorText(t, errorState, 'header')}
        </div>
        <div className={`l-error-screen-message${calcErrorScreenClassModifier()}`}>
          {renderErrorText(t, errorState, 'message')}
        </div>
      </div>
      <div className='l-error-screen-footer'>
        <Footer />
      </div>
    </>
  );
};

export default ErrorScreen;
