import { buildI18n, ILocaleOptions } from '@incentivegames/ig-frontend-common/lib/utilities/i18n';

import english from './locales/english/translation.json';
import Config from './config';

const languageConfig: ILocaleOptions = {
  resources: {
    en: {
      translations: english,
    },
  },
  language: Config.settings.defaultLanguage,
  fallback: Config.settings.fallbackLanguage,
};

export default buildI18n(languageConfig);
