import { AllScreens } from 'screens/Screen.types';

import LoadingScreen from './screens/LoadingScreen/LoadingScreen';
import HistoryModal from './screens/HistoryModal/HistoryModal';
import StartScreen from './screens/StartScreen/StartScreen';
import WaitScreen from './screens/WaitScreen/WaitScreen';
import ChoiceScreen from './screens/ChoiceScreen/ChoiceScreen';
import ConfirmedScreen from './screens/ConfirmedScreen/ConfirmedScreen';
import ResultScreen from './screens/ResultScreen/ResultsScreen';
import UpsellScreen from './screens/UpsellScreen/UpsellScreen';
import ErrorScreen from './screens/ErrorScreen/ErrorScreen';
import PayoutTableScreen from './screens/PayoutTableScreen/PayoutTableScreen';

/**
 * Input React components to render each screen here
 */
export const CustomScreens: AllScreens = {
  Loading: LoadingScreen,
  HistoryModal: HistoryModal,
  Start: StartScreen,
  Wait: WaitScreen,
  Choice: ChoiceScreen,
  Confirmed: ConfirmedScreen,
  Upsell: UpsellScreen,
  Results: ResultScreen,
  Error: ErrorScreen,
  PayoutTable: PayoutTableScreen,
};
